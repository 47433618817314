import React, { useEffect, useState } from 'react'
import ChooseProduct from '../../component/choose-product/ChooseProduct'
import { fetchListMostShared, fetchListTop } from '../../api-services/home-api'
import { message } from 'antd'
import moment from 'moment'
import { fetchListProduct, getDetailProduct } from '../../api-services/product-api'
import * as queryString from 'query-string'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { addProductToCart } from '../../api-services/cart-api'
import { setRefreshCart, toggleShowBoxConfirmCart } from '../../redux/actions/search-action'
import { toggleShowLoading } from '../../redux/actions/home-actions'
import { isMaxTableScreen, isMobileScreen } from '../../functions/common-func'

function ChooseProductContainer({ dispatch, location, history, isLoggedIn, totalProduct, refreshCart }) {
    const [listMostShared, setListMostShared] = useState([])
    const [listTop, setListTop] = useState([])
    const [listProduct, setListProduct] = useState([])
    const [listProductSearchDesktop, setListProductSearchDesktop] = useState([])
    const [isOpenProduct, setIsOpenProduct] = useState(false)
    const [nameProduct, setNameProduct] = useState('')
    const [chooseProduct, setChooseProduct] = useState('')
    const [validateName, setValidateName] = useState({ status: 'success', text: '' })
    const [reLoadingSuggestion, setReLoadingSuggestion] = useState(false)
    const [pagingSuggestion, setPagingSuggestion] = useState({ page: 1, pageSize: 25, total: 1 })
    const [animation, setAnimation] = useState(false)
    message.config({
        top: 100,
        duration: 3,
        maxCount: 1,
    })

    if(!isLoggedIn) {
        window.location = `${window.location.origin}/login`;
    }
    
    useEffect(() => {
        if (refreshCart) {
            setAnimation(true)
            setTimeout(() => {
                setAnimation(false)
            }, 3000)
        }
    }, [refreshCart])

    useEffect(() => {
        let filter = '?'
        filter += 'page=1&page_size=25'
        getListProduct(filter)
    }, [])

    useEffect(() => {
        const query = queryString.parse(location.search)
        const _name = query.name || ''
        const category_id = query.category_id || ''
        const category_name = query.category_name || ''
        const page = query.page || pagingSuggestion.page
        const pageSize = query.pageSize || pagingSuggestion.pageSize
        let filter = '?'
        if (page) filter += `&page=${page}`
        if (pageSize) filter += `&page_size=${pageSize}`
        if (category_id) filter += `&category_id=${category_id}`
        if (category_name) filter += `&category_name=${category_name}`
        if (_name) filter += `&name=${_name}`
        // setCategoryId(category_id)
        setNameProduct(_name)
        getListProduct(filter)
    }, [location])

    useEffect(() => {
        onSearch(nameProduct)
    }, [nameProduct])

    useEffect(() => {
        let check = !!(pagingSuggestion.page < pagingSuggestion.total)
        if (pagingSuggestion && reLoadingSuggestion && check) {
            let filter = '?'
            filter += `page=${pagingSuggestion.page + 1}&page_size=${pagingSuggestion.pageSize}`
            getListProduct(filter)
        }
    }, [reLoadingSuggestion])

    useEffect(() => {
        getListTop()
        getListMostShared()
    }, [])

    const onSearch = async (keyword) => {
        if (keyword.trim().length >= 2) {
            setIsOpenProduct(true)
            try {
                let filter = '?'
                filter += `name=${keyword}`
                await getListProductSearch(filter)
            } catch (e) {
                throw e
            }
        } else {
            if (keyword.length === 0) {
                setIsOpenProduct(false)
                setListProductSearchDesktop([])
            }
        }
    }
    // get danh sách sản phẩm khi search
    const getListProductSearch = async (filter) => {
        try {
            const data = await fetchListProduct(filter)
            if (data && data.status === 200) {
                setListProductSearchDesktop(data.data)
            } else {
                message.error(data.message)
                setListProductSearchDesktop([])
            }
        } catch (e) {
            setListProductSearchDesktop([])
            throw e
        }
    }

    const getListProduct = async (filter) => {
        try {
            !(isMobileScreen() || isMaxTableScreen()) && dispatch(toggleShowLoading(true))
            const data = await fetchListProduct(filter)
            dispatch(toggleShowLoading(false))

            if (data && data.status === 200) {
                if (reLoadingSuggestion) {
                    let _list = [...listProduct]
                    _list = _list.concat(data.data)
                    setListProduct(_list)
                } else {
                    setListProduct(data.data)
                }
                setPagingSuggestion(data.paging)
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    const getListMostShared = async () => {
        try {
            const data = await fetchListMostShared()
            if (data && data.status === 200) {
                setListMostShared(data.data)
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    const getListTop = async () => {
        try {
            const params = {}
            params.page = 1
            params.page_size = 5
            const data = await fetchListTop(params)
            if (data && data.status === 200) {
                setListTop(data.data)
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    // const handleKeyPressName = (e) => {
    //     if (e.charCode === 13) {
    //         const query = queryString.parse(location.search)
    //         let new_query = { ...query }
    //         nameProduct ? (new_query = { ...query, name: nameProduct }) : delete new_query.name
    //         const stringify = queryString.stringify(new_query)
    //         history.push(`${location.pathname}?${stringify}`)
    //     }
    // }

    const handleClick = () => {
        const query = queryString.parse(location.search)
        let new_query = { ...query }
        nameProduct ? (new_query = { ...query, name: nameProduct }) : delete new_query.name
        const stringify = queryString.stringify(new_query)
        history.push(`${location.pathname}?${stringify}`)
    }

    const handleChangeName = (e) => {
        setNameProduct(e.target.value)
        setValidateName({ status: 'success', text: '' })
    }

    const handleProductToCart = async (item) => {
        if(item.rangePrice) {
            history.push(`/product/${item.id}`)
        } else {
        try {
            const data = {
                productId: item.id,
                quantity: 1,
                variantId: item.variantId,
                productAttributes: [],
            }
            const product = await getDetailProduct(item.id)
            if (product && product.status === 200) {
                setChooseProduct(product.data)
            }
            if (product.data?.colors?.length > 0 || product.data?.sizes?.length > 0) {
                //  trường hợp có options để chọn cho sản phẩm
                dispatch(toggleShowBoxConfirmCart(true, 'Chọn loại sản phẩm', 'success'))
            } else {
                // trường hợp không có options để chọn cho sản phẩm => add sản phẩm
                if (!isLoggedIn) {
                    // trường hợp là buyer
                    history.push(`/thanh-toan/${item.id}`)
                } else {
                    // trường hợp là publisher
                    const result = await addProductToCart(data)
                    if (result && result.status === 200) {
                        // history.push('/checkout/cart')
                        dispatch(setRefreshCart(moment()))
                        message.success('Thêm sản phẩm vào giỏ hàng thành công')
                    }
                }
            }
        } catch (e) {
            if (e && e.response && e.response.data) {
                if (e.response.data.code === 'E00018' && item.quantity > 0) {
                    message.error(
                        'Bạn đã chọn hết số lượng còn lại của sản phẩm, không thể chọn thêm được nữa, vui lòng kiểm tra giỏ hàng'
                    )
                    return
                }
                if (e.response.data.code === 'E00018') {
                    message.error('Sản phầm đã hết hàng')
                    return
                } else {
                    message.error(e.response.data.message)
                }
            }
            throw e
        }
    }
    }

    return (
        <ChooseProduct
            listProduct={listProduct}
            listProductSearchDesktop={listProductSearchDesktop}
            nameProduct={nameProduct}
            totalProduct={totalProduct}
            validateName={validateName}
            animation={animation}
            listTop={listTop}
            listMostShared={listMostShared}
            chooseProduct={chooseProduct}
            reLoadingSuggestion={reLoadingSuggestion}
            pagingSuggestion={pagingSuggestion}
            setReLoadingSuggestion={setReLoadingSuggestion}
            isOpenProduct={isOpenProduct}
            handleClick={handleClick}
            // handleKeyPressName={handleKeyPressName}
            handleChangeName={handleChangeName}
            handleProductToCart={handleProductToCart}
        />
    )
}

const mapStateToProps = function (state) {
    return {
        isLoggedIn: state.homeReducer.isLoggedIn,
        isPublisher: state.homeReducer.isPublisher,
        totalProduct: state.searchReducer.totalProduct,
        refreshCart: state.searchReducer.refreshCart,
    }
}
export default withRouter(connect(mapStateToProps)(ChooseProductContainer))
