import axios from 'axios'
import { getSession, removeSession, removeUserSession } from '../functions/common-func'

const ApiMicroService = axios.create({
    baseURL: process.env.REACT_APP_URL_API_SERVICE,
    responseType: 'json',
})

let user = getSession('userInfo')
// const isEnglish = window.location.href.includes('lang=en')
ApiMicroService.defaults.timeout = 20000
ApiMicroService.defaults.headers.common['Authorization'] =
    user && user.tokenInfo ? 'Bearer ' + user['tokenInfo'].accessToken : ''
// ApiMicroService.defaults.headers.common['Accept-Language'] = user ? 'en' : 'vi'
ApiMicroService.defaults.headers.post['Content-Type'] = 'application/json'

ApiMicroService.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if (
            (response.data && response.data.error_code === 401) ||
            (response.data && response.data.code === 401) ||
            (response.data && response.data.statusCode === 401) ||
            (response.data && response.data.status === 401)
        ) {
            removeSession('userInfo')
            removeSession('role')
            window.location.href = `/login`
        }
        return response
    },
    function (error) {
		if(error && error.response) {
			if (error.response.status === 401) {
				removeSession('userInfo');
				removeSession('role');
				window.location.href = `/login`;
			} 
			if( error.response.data && error.response.data.statusCode === 401) {
				removeSession('userInfo');
				removeSession('role');
				window.location.href = `/login`;
			}
		}
        
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error)
    }
)

export default ApiMicroService
