import React, { useState, useEffect } from 'react'
import Notification from '../../component/notification/Notification'
import { withRouter } from 'react-router'
import { connect, useDispatch } from 'react-redux'
import { toggleShowLoading } from '../../redux/actions/home-actions'
import {
    fetchListNotification,
    fetchListNotificationAccesstrade,
    readNotification
} from '../../api-services/notionfication-api'

function NotificationContainer({ history, location,isLoggedIn }) {
    const [typeComponent, setTypeComponent] = useState('order')
    const [listNotification, setListNotification] = useState(null)
    const [listNotiOrder, setListNotiOrder] = useState(null)
    const dispatch = useDispatch()

    if(!isLoggedIn) {
        window.location = `${window.location.origin}/login`;
    }
    localStorage.removeItem('cartCoupon');

    useEffect(() => {
        if (typeComponent !== 'order') {
            getListNotificationAccesstrade()
        } else {
            getListNotification()
        }
    }, [location, typeComponent])

    const getListNotificationAccesstrade = async () => {
        try {
            dispatch(toggleShowLoading(true))
            const data = await fetchListNotificationAccesstrade()
            if (data && data.status === 200) {
                setListNotification(data.data)

            }
            dispatch(toggleShowLoading(false))
        } catch (e) {
            dispatch(toggleShowLoading(false))
            throw e
        }
    }

    const getListNotification = async () => {
        try {
            dispatch(toggleShowLoading(true))
            const data = await fetchListNotification()
            if (data && data.status === 200) {
                setListNotiOrder(data.data)
            }
            dispatch(toggleShowLoading(false))
        } catch (e) {
            dispatch(toggleShowLoading(false))
            throw e
        }
    }

    const onClose = () => {
        history.goBack()
    }

    const handleTypeComponent = (type) => {
        setTypeComponent(type)
        // const query = queryString.parse(location.search)
        // let new_query = { ...query }
        // type ? (new_query.type = type) : delete new_query.type
        // const stringify = queryString.stringify(new_query)
        // history.push(`${location.pathname}?${stringify}`)
    }

    const handleClickItemNotificationOrder = (item) => {
        try {
            const data = readNotification(item.id)

            history.push('/order/' + item.data)
        } catch (e) {
            throw e
        }
    }

    return (
        <Notification
            listNoti={listNotification}
            listNotiOrder={listNotiOrder}
            onClose={onClose}
            typeComponent={typeComponent}
            setTypeComponent={setTypeComponent}
            handleTypeComponent={handleTypeComponent}
            handleClickItemNotificationOrder={handleClickItemNotificationOrder}
        />
    )
}
const mapStateToProps = function (state) {
    return {
        isLoggedIn: state.homeReducer.isLoggedIn
    }
}
export default withRouter(connect(mapStateToProps)(NotificationContainer))
