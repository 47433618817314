import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/min/locales.min'
import ListOrder from '../../component/list-order/ListOrder'
import { fetchListOrder } from '../../api-services/order-id'
import { useLocation, withRouter } from 'react-router'
import { connect, useSelector } from 'react-redux'
import * as queryString from 'query-string'
import { toggleShowLoading } from '../../redux/actions/home-actions'
import { toggleClassNoScroll } from '../../functions/common-func'

function ListOrderContainer({ dispatch, location, history }) {
    const [listOrder, setListOrder] = useState(false)
    const [paging, setPaging] = useState({ page: 1, pageSize: 15, total: 1 })
    const [orderCode, setOrderCode] = useState('')
    const [status, setStatus] = useState('all')
    const [isOpenDp, setIsOpenDp] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const { pathname, search } = useLocation()

    const { isLoggedIn } = useSelector((state) => {
        return {
            isLoggedIn: state.homeReducer.isLoggedIn,
        }
    })

    if(!isLoggedIn) {
        window.location = `${window.location.origin}/login`;
    }

    useEffect(() => {
        const query = queryString.parse(search)
        const paramSearch = {}
        paramSearch.page = query.page || paging.page
        paramSearch.page_size = query.pageSize || paging.pageSize

        let filter = ''
        if (query.orderCode) {
            filter += 'keyWord=' + query.orderCode
        }
        if (query.status) {
            filter += '&status=' + query.status
            setStatus(query.status)
        }
        if (query.endDate) {
            filter += '&endDate=' + moment(query.endDate, 'DDMMYYYY').format('YYYY/MM/DD')
        }
        if (query.startDate) {
            filter += '&startDate=' + moment(query.startDate, 'DDMMYYYY').format('YYYY/MM/DD')
        }
        filter += '&page=' + paramSearch.page
        filter += '&page_size=' + paramSearch.page_size
        paramSearch.status = query.status
        getListOrder(filter)
    }, [search, pathname])

    const handleFilterStatus = (status) => {
        let _status = status
        if (status === 'all') {
            setStatus('')
            _status = ''
        }
        setStatus(status)
        const query = queryString.parse(search)
        let new_query = { ...query }
        _status ? (new_query.status = _status) : delete new_query.status
        const stringify = queryString.stringify(new_query)
        history.push(`${pathname}?${stringify}`)
    }

    const getListOrder = async (paramSearch) => {
        try {
            dispatch(toggleShowLoading(true))
            const data = await fetchListOrder(paramSearch)
            dispatch(toggleShowLoading(false))
            if (data && data.status === 200) {
                setListOrder(data.data)
                setPaging(data.paging)
            } else {
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))
            throw e
        }
    }

    const onChangeSearch = (e) => {
        setOrderCode(e.target.value)
    }

    const handleSearch = () => {
        const value = orderCode
        const query = queryString.parse(location.search)
        let new_query = { ...query }
        value ? (new_query.orderCode = value) : delete new_query.orderCode
        const stringify = queryString.stringify(new_query)
        history.push(`${location.pathname}?${stringify}`)
    }

    const handleKeyPress = (e) => {
        if (e.charCode === 13) {
            const value = orderCode || e.target.value
            const query = queryString.parse(location.search)
            let new_query = { ...query }
            value ? (new_query.orderCode = value) : delete new_query.orderCode
            const stringify = queryString.stringify(new_query)
            history.push(`${location.pathname}?${stringify}`)
        }
    }

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day')
    }
    const onChange = (rangeDate) => {
        if (rangeDate) {
            setEndDate(rangeDate[1])
            setStartDate(rangeDate[0])
            const query = queryString.parse(search)
            let new_query = { ...query }
            rangeDate[0] ? (new_query.startDate = moment(rangeDate[0]).format('DDMMYYYY')) : delete new_query.startDate
            rangeDate[1] ? (new_query.endDate = moment(rangeDate[1]).format('DDMMYYYY')) : delete new_query.endDate
            const stringify = queryString.stringify(new_query)
            history.push(`${pathname}?${stringify}`)
        } else {
            setEndDate(null)
            setStartDate(null)
            const query = queryString.parse(search)
            let new_query = { ...query }
            delete new_query.startDate
            delete new_query.endDate
            const stringify = queryString.stringify(new_query)
            history.push(`${pathname}?${stringify}`)
        }
    }
    const handleOpenDp = (open) => {
        setIsOpenDp(open)
        toggleClassNoScroll()
    }
    return (
        <ListOrder
            onChangeSearch={onChangeSearch}
            handleKeyPress={handleKeyPress}
            handleFilterStatus={handleFilterStatus}
            disabledDate={disabledDate}
            handleOpenDp={handleOpenDp}
            onChange={onChange}
            status={status}
            handleSearch={handleSearch}
            listOrder={listOrder}
            isOpenDp={isOpenDp}
            startDate={startDate}
            endDate={endDate}
            paging={paging}
        />
    )
}

const mapStateToProps = function (state) {
    return {
        openInfoProduct: state.listOrderReducer.openInfoProduct,
    }
}
export default withRouter(connect(mapStateToProps)(ListOrderContainer))
