import React, { useEffect, useState } from 'react'
import { fetchListDealHot, fetchListTop } from '../../api-services/home-api'
import { toggleShowLoading } from '../../redux/actions/home-actions'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { message } from 'antd'
import DealHotList from '../../component/deal-hot/DealHotList'

function DealHotListContainer({ location, dispatch, history,isLoggedIn }) {
    const [listDealHot, setListDealHot] = useState(false)
    const [topProducts, setTopProducts] = useState(false)

    if(!isLoggedIn) {
        window.location = `${window.location.origin}/login`;
    }

    useEffect(() => {
        getAllDealHot()
        getTopProducts()
    }, [location])

    const getAllDealHot = async () => {
        try {
            const params = {
                page: 1,
                page_size: 30,
                sort: 'ASC',
            }
            const data = await fetchListDealHot(params)
            if (data && data.data.length > 0) {
                setListDealHot(data)
            }
        } catch (e) {
            throw e
        }
    }

    const getTopProducts = async () => {
        try {
            const params = {}
            params.page = 1
            params.page_size = 5
            const data = await fetchListTop(params)
            if (data && data.status === 200) {
                setTopProducts(data.data)
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    return <DealHotList listDealHot={listDealHot} topProducts={topProducts} />
}

const mapStateToProps = function (state) {
    return {
        isLoggedIn: state.homeReducer.isLoggedIn,
        isPublisher: state.homeReducer.isPublisher,
    }
}
export default withRouter(connect(mapStateToProps)(DealHotListContainer))
