import React, { useState, useEffect } from 'react'
import Dashboard from '../../component/dashboard/Dashboard'
import { connect, useDispatch } from 'react-redux'
import moment from 'moment'
import { useLocation, useHistory, withRouter } from 'react-router'
import { fetchReportChart, fetchReportCurrent, fetchReportPublisher } from '../../api-services/dash-board-api'
import { toggleShowLoading } from '../../redux/actions/home-actions'
import { fetchListTop } from '../../api-services/home-api'
import { message } from 'antd'
import * as queryString from 'query-string'
import { toggleClassNoScroll } from '../../functions/common-func'

function DashboardContainer({isLoggedIn}) {
    const [dataCurrent, setDataCurrent] = useState(null)
    const [listTop, setListTop] = useState([])
    const [listTopPublisher, setListTopPublisher] = useState([])
    const [dataChart, setDataChart] = useState({})
    const [filter, setFilter] = useState('preApproved')
    const [isOpenDp, setIsOpenDp] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const dispatch = useDispatch()
    const history = useHistory()
    const { search, pathname } = useLocation()

    if(!isLoggedIn) {
        window.location = `${window.location.origin}/login`;
    }

    localStorage.removeItem('cartCoupon');

    useEffect(() => {
        getListTop()
        getReportChart()
        getListTopPublisher()
    }, [])

    useEffect(() => {
        const query = queryString.parse(search)
        const paramSearch = {}
        if (query.endDate) {
            paramSearch.endDate = moment(query.endDate, 'DDMMYYYY').format('YYYY/MM/DD')
            setEndDate(moment(query.endDate, 'DDMMYYYY'))
        }
        if (query.startDate) {
            paramSearch.startDate = moment(query.startDate, 'DDMMYYYY').format('YYYY/MM/DD')
            setStartDate(moment(query.startDate, 'DDMMYYYY'))
        }
        getReportCurrent(paramSearch)
    }, [pathname, search])

    useEffect(() => {
        const query = queryString.parse(search)
        if (query.filter) {
            setFilter(query.filter)
        }
    }, [search, pathname])

    const getListTop = async () => {
        try {
            const params = {}
            params.page = 1
            params.page_size = 5
            const data = await fetchListTop(params)
            if (data && data.status === 200) {
                setListTop(data.data)
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    const getListTopPublisher = async () => {
        try {
            dispatch(toggleShowLoading(true))

            const data = await fetchReportPublisher()
            dispatch(toggleShowLoading(false))

            if (data && data.status === 200) {
                setListTopPublisher(data.data)
            } else {
                // message.error(data.message)
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))
            throw e
        }
    }

    const getReportCurrent = async (paramSearch) => {
        try {
            dispatch(toggleShowLoading(false))
            const data = await fetchReportCurrent(paramSearch)
            setTimeout(() => {
                dispatch(toggleShowLoading(false))
            }, 200)
            if (data && data.status === 200) {
                setDataCurrent(data.data)
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))

            throw e
        }
    }

    const getReportChart = async () => {
        try {
            dispatch(toggleShowLoading(true))
            const data = await fetchReportChart()
            dispatch(toggleShowLoading(false))
            if (data && data.status === 200) {
                setDataChart(data.data)
                let labels = []
                let datasets = [
                    {
                        label: 'Hoa hồng',
                        backgroundColor: '#FA8C16',
                        borderColor: '#FA8C16',
                        borderWidth: 1,
                        hoverBackgroundColor: '#FA8C16',
                        hoverBorderColor: '#FA8C16',
                        data: [],
                    },
                ]
                if(data.data?.length > 0) {
                    data.data.forEach((res) => {
                        labels.push(res.unit)
                        datasets[0].data.push(res.value)
                    })
                }
                setDataChart({
                    labels: labels,
                    datasets: datasets,
                })
            }
        } catch (e) {
            throw e
        }
    }

    const handleOnchangeFilter = (value) => {
        const query = queryString.parse(search)
        let new_query = { ...query }
        value ? (new_query.filter = value) : delete new_query.name
        const stringify = queryString.stringify(new_query)
        history.push(`${pathname}?${stringify}`)
    }

    const handleCloseDp = () => {
        setIsOpenDp(false)
        toggleClassNoScroll()
    }

    const handleOpenDp = (open) => {
        setIsOpenDp(open)
        toggleClassNoScroll()
    }

    const onChange = (rangeDate) => {

        if (rangeDate) {
            setEndDate(rangeDate[1])
            setStartDate(rangeDate[0])
            const query = queryString.parse(search)
            let new_query = { ...query }
            rangeDate[1] ? (new_query.startDate = moment(rangeDate[0]).format('DDMMYYYY')) : delete new_query.startDate
            rangeDate[0] ? (new_query.endDate = moment(rangeDate[1]).format('DDMMYYYY')) : delete new_query.endDate
            const stringify = queryString.stringify(new_query)
            history.push(`${pathname}?${stringify}`)
        }else {
            setEndDate(null)
            setStartDate(null)
            const query = queryString.parse(search)
            let new_query = { ...query }
            delete new_query.startDate
            delete new_query.endDate
            const stringify = queryString.stringify(new_query)
            history.push(`${pathname}?${stringify}`)
        }
    }

    return (
        <Dashboard
            dataCurrent={dataCurrent}
            filter={filter}
            listTop={listTop}
            listTopPublisher={listTopPublisher}
            dataChart={dataChart}
            isOpenDp={isOpenDp}
            startDate={startDate}
            endDate={endDate}
            handleCloseDp={handleCloseDp}
            handleOpenDp={handleOpenDp}
            onChange={onChange}
            handleOnchangeFilter={handleOnchangeFilter}
        />
    )
}
const mapStateToProps = function (state) {
    return {
        isLoggedIn: state.homeReducer.isLoggedIn,
        isPublisher: state.homeReducer.isPublisher,
    }
}

export default withRouter(connect(mapStateToProps)(DashboardContainer))
