import React, { useEffect, useState } from 'react'
import Account from '../../component/account/Account'
import { removeSession } from '../../functions/common-func'
import { setIsLoggedIn, togglePopupShare, toggleShowLoading } from '../../redux/actions/home-actions'
import { useLocation, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { message } from 'antd'
import * as queryString from 'query-string'

function AccountContainer({ history, dispatch, isLoggedIn, location }) {
    const { pathname } = useLocation()
    const [typeAccount, setTypeAccount] = useState('info');

    if(!isLoggedIn) {
        window.location = `${window.location.origin}/login`;
    }

    localStorage.removeItem('cartCoupon');

    useEffect(() => {
        const query = queryString.parse(location.search)
        if (query.type) {
            setTypeAccount(query.type)
        }
        dispatch(toggleShowLoading(true))
        setTimeout(() => {
            dispatch(toggleShowLoading(false))
        }, 500)
    }, [location])

    useEffect(() => {
        if (!isLoggedIn) {
            history.push('/login')
        }
    }, [isLoggedIn])
    const handleLogout = () => {
        removeSession('userInfo')
        removeSession('role')
        dispatch(setIsLoggedIn(false))
        let url = '/login'
        history.push(url)
    }

    const handleSetType = (type) => {
        setTypeAccount(type)
        const query = queryString.parse(location.search)
        let new_query = { ...query }
        type ? (new_query.type = type) : delete new_query.type
        const stringify = queryString.stringify(new_query)
        history.push(`${pathname}?${stringify}`)
    }

    const handleShareWithMobile = async () => {
        if (navigator.share === undefined) {
            dispatch(togglePopupShare(true, 'link', 'Gửi lời mời sử dụng Dimuadi'))
        } else {
            try {
                const shareData = {
                    title: 'DimuaDi|Accesstrade',
                    text: 'DimuaDi|Accesstrade',
                    url: process.env.REACT_APP_URL_WEBSITE,
                }
                await navigator.share(shareData)
                message.success('Chia sẻ thành công')
            } catch (error) {
                throw error
            }
        }
    }

    return (
        <Account
            typeAccount={typeAccount}
            handleLogout={handleLogout}
            handleShareWithMobile={handleShareWithMobile}
            handleSetType={handleSetType}
        />
    )
}

const mapStateToProps = function (state) {
    return {
        isPublisher: state.homeReducer.isPublisher,
        isLoggedIn: state.homeReducer.isLoggedIn,
    }
}
export default withRouter(connect(mapStateToProps)(AccountContainer))
