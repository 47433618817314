import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchCoupons, getDetailCoupon, getProductCoupon } from '../../api-services/coupon-product-api';
import * as queryString from 'query-string';
import { message } from 'antd';
import CouponList from '../../component/coupon/CouponList';
import { toggleShowLoading } from '../../redux/actions/home-actions';

const CouponContainer = ({history, dispatch,location,isPublisher,isLoggedIn}) => {
    const [couponList, setCouponList] = useState([]);
    const [paging, setPaging] = useState({page:1, pageSize:25, total:0,totalPage:0});
    const [products,setProducts] = useState([]);
    const [coupon,setCoupon]=useState(null);
    const [reLoading, setReLoading] = useState(false)

    if(!isLoggedIn) {
        window.location = `${window.location.origin}/login`;
    }

    localStorage.removeItem('cartCoupon');
    
    useEffect(()=> {
        const params = queryString.parse(location.search);
        const id = params.coupon_id;
        setPaging({page:1, pageSize:25, total:0});
        setReLoading(false);
        if(id > 0) {
            getDetailCoupons(id);
            getListProduct(id,params);
        } else {
            getCPListDesktop(params);
        }
    },[location]);

    useEffect(() => {
        const paramSearch = {}
        const params = queryString.parse(location.search);
        const id = params.coupon_id;
        if(id > 0) {
            if (reLoading && paging.page < paging.totalPage) {
                paramSearch.page = paging.page + 1;
                paramSearch.page_size = paging.pageSize;
                getListProductMobile(id,paramSearch);
            }
        } else {
            if (reLoading && couponList?.length < paging.total) {
                paramSearch.page = paging.page + 1
                paramSearch.page_size = paging.pageSize
                getCPMobile(paramSearch);
            }
        }
        
    }, [reLoading])


    const getCPListDesktop = async (params) => {
        try {
            dispatch(toggleShowLoading(true));
            const resCoupons = await fetchCoupons(params)
            if (resCoupons.status === 200 && resCoupons.data) {
                setCouponList(resCoupons.data);
                setPaging(resCoupons.paging);
                dispatch(toggleShowLoading(false));
            } else {
                message.error(resCoupons.message);
                dispatch(toggleShowLoading(false));
            }
        } catch (e) {
            throw e
        }
    }
    const getCPMobile = async (params) => {

        try {
            dispatch(toggleShowLoading(true));
            const resCoupons = await fetchCoupons(params)
            if (resCoupons.status === 200 && resCoupons.data) {
                if(reLoading) {
                    setCouponList((olddata) =>[...olddata,...resCoupons.data]);
                    setPaging(resCoupons.paging);
                } else {
                    setCouponList(resCoupons.data);
                    setPaging(resCoupons.paging);
                }
                dispatch(toggleShowLoading(false));
            } else {
                message.error(resCoupons.message);
                dispatch(toggleShowLoading(false));
            }
        } catch (e) {
            throw e
        }
    }
    const getListProduct = async (id,filter) => {
        try {
            const data = await getProductCoupon(id,filter)
            if (data && data.status === 200) {
                setProducts(data.data)
                setPaging(data.paging)
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    };
    const getListProductMobile = async (id,filter) => {
        try {
            dispatch(toggleShowLoading(true));
            const data = await getProductCoupon(id,filter)
            if (data && data.status === 200) {
                if(reLoading) {
                    let _list = [...products];
                    _list = _list.concat(data.data)
                    setProducts(_list);
                    setPaging(data.paging);
                } else {
                    setProducts(data.data);
                    setPaging(data.paging);
                }
                dispatch(toggleShowLoading(false));
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    };
    const getDetailCoupons = async (id) => {
        try {
            const resCoupons = await getDetailCoupon(id)
            if (resCoupons.status === 200 && resCoupons.data) {
                setCoupon(resCoupons.data);
            } else {
                message.error(resCoupons.message);
            }
        } catch (e) {
            throw e
        }
    };
    return (
    <CouponList 
    couponList={couponList} 
    products={products}
    coupon={coupon}
    isPublisher={isPublisher}
    paging={paging}
    setPaging={setPaging}
    setReLoading={setReLoading}
    reLoading={reLoading}
    history={history}
    location={location}
    dispatch={dispatch}
    />
    );
}
const mapStateToProps = function (state) {
    return {
        isLoggedIn: state.homeReducer.isLoggedIn,
        isPublisher: state.homeReducer.isPublisher,
    }
}
export default withRouter(connect(mapStateToProps)(CouponContainer)) ;