import React, { useEffect, useState } from 'react'
import { fetchDealHotDetail, fetchListTop } from '../../api-services/home-api'
import { fetchProductInDeal } from '../../api-services/product-api'
import { toggleShowLoading } from '../../redux/actions/home-actions'
import DealHot from '../../component/deal-hot/DealHot'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { message } from 'antd'

function DealHotContainer({ location, dispatch, history, isLoggedIn, isPublisher }) {
    const [dealInfo, setDealInfo] = useState(false)
    const [products, setProducts] = useState(false)
    const [topProducts, setTopProducts] = useState(false)

    if(!isLoggedIn) {
        window.location = `${window.location.origin}/login`;
    }

    useEffect(() => {
        const pathname = location.pathname
        const id = pathname.split('/').slice(-1).pop()
        getDataDetail(id)
        getProductInDeal(id)
        getTopProducts()
    }, [location])

    const getDataDetail = async (id) => {
        try {
            dispatch(toggleShowLoading(true))
            const data = await fetchDealHotDetail(id)
            dispatch(toggleShowLoading(false))
            if (data && data.status === 200) {
                setDealInfo(data.data)
            }
        } catch (e) {
            throw e
        }
    }

    const getProductInDeal = async (id) => {
        try {
            const data = await fetchProductInDeal(id)
            if (data && data.data.length > 0) {
                setProducts(data)
            }
        } catch (e) {
            throw e
        }
    }

    const getTopProducts = async () => {
        try {
            const params = {}
            params.page = 1
            params.page_size = 5
            const data = await fetchListTop(params)
            if (data && data.status === 200) {
                setTopProducts(data.data)
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    return <DealHot dealInfo={dealInfo} products={products} topProducts={topProducts} />
}

const mapStateToProps = function (state) {
    return {
        isLoggedIn: state.homeReducer.isLoggedIn,
        isPublisher: state.homeReducer.isPublisher,
    }
}
export default withRouter(connect(mapStateToProps)(DealHotContainer))
