import React, { useEffect, useState } from 'react'
import CreateManyOrder from '../../component/create-order/CreateManyOrder'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import 'moment/min/locales.min'
import { toggleShowLoading } from '../../redux/actions/home-actions'
import { fetchListProvince } from '../../api-services/address-api'
import { message } from 'antd'
import { deleteProductCart, fetchProductFromCart, updateProductCart} from '../../api-services/cart-api'
import { toggleShowConfirmDelete } from '../../redux/actions/checkout-action'
import {convertTotalCommission, getSessionAddressReview, renderDiscount, renderPriceAfterCP, saveAddressReview} from '../../functions/common-func'
import { setRefreshCart } from '../../redux/actions/search-action'

function CreateManyOrderContainer() {
    const [listAddress] = useState([])
    const [listProductFromCart, setListProductFromCart] = useState([])
    // const [isAddress, setIsAddress] = useState({})
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalCommission, setTotalCommission] = useState(0)
    const [isRefresh, setIsRefresh] = useState(false)
    const [dataConfirmDelete, setDataConfirmDelete] = useState({})
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [note, setNote] = useState('')
    const [address, setAddress] = useState('')
    const [province, setProvince] = useState(false)
    const [district, setDistrict] = useState(false)
    const [commune, setCommune] = useState(false)
    const [isDisableDistrict, setIsDisableDistrict] = useState(true)
    const [isDisableCommune, setIsDisableCommune] = useState(true)
    const [listProvince, setListProvince] = useState([])
    const [listDistrict, setListDistrict] = useState([])
    const [listCommune, setListCommune] = useState([])
    const [isDefault, setIsDefault] = useState(false)
    // const [idAddress, setIdAddress] = useState(false)
    const [isCompanyAddress, setIsCompanyAddress] = useState(false)
    const [validateName, setValidateName] = useState({ status: '', text: '' })
    const [validatePhone, setValidatePhone] = useState({ status: '', text: '' })
    const [validateAddress, setValidateAddress] = useState({ status: '', text: '' })
    const [validateProvince, setValidateProvince] = useState({ status: '', text: '' })
    const [validateDistrict, setValidateDistrict] = useState({ status: '', text: '' })
    const [validateCommune, setValidateCommune] = useState({ status: '', text: '' })
    const [couponLocal, setCouponLocal] = useState([]);
    const dispatch = useDispatch()
    const history = useHistory()
    const { isAddress } = useSelector((state) => {
        return {
            isAddress: state.checkoutReducer.isAddress,
        }
    })
    const { isLoggedIn } = useSelector((state) => {
        return {
            isLoggedIn: state.homeReducer.isLoggedIn,
        }
    })

    if(!isLoggedIn) {
        window.location = `${window.location.origin}/login`;
    }

    const onChangeIsDefault = (e) => {
        setIsDefault(e.target.checked)
    }

    const onChangeIsCompanyAddress = (e) => {
        setIsCompanyAddress(e.target.checked)
    }

    const clearValidate = () => {
        setValidateName({ status: '', text: '' })
        setValidatePhone({ status: '', text: '' })
        setValidateAddress({ status: '', text: '' })
        setValidateProvince({ status: '', text: '' })
        setValidateDistrict({ status: '', text: '' })
        setValidateCommune({ status: '', text: '' })
    }

    const handleChangeName = (e) => {
        setName(e.target.value)
        clearValidate()
    }

    const handleChangePhone = (e) => {
        setPhone(e.target.value)
        clearValidate()
    }

    const handleChangeNote = (e) => {
        setNote(e.target.value)
    }

    const handleChangeAddress = (e) => {
        setAddress(e.target.value)
        clearValidate()
    }

    const handleChangeProvince = async (value) => {
        setProvince(value)
        setDistrict(null)
        setCommune(null)
        clearValidate()
        if (value) {
            await getListDistrict(value.value)
        }
    }

    const handleChangeDistrict = async (value) => {
        setDistrict(value)
        setCommune(null)
        if (value) {
            await getListCommune(value.value)
        }
        clearValidate()
    }

    const handleChangeCommune = (value) => {
        setCommune(value)
        clearValidate()
    }

    const getListDistrict = async (province) => {
        try {
            let filter = '?type=district'
            if (province) {
                filter += `&parent_id=${province}`
            }
            const data = await fetchListProvince(filter)
            if (data && data.status === 200) {
                setListDistrict(
                    data.data.map((res) => {
                        return {
                            label: res.name,
                            value: res.id,
                        }
                    })
                )
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    const getListCommune = async (district_id) => {
        try {
            let filter = '?type=ward'
            if (district_id) {
                filter += `&parent_id=${district_id}`
            }
            const data = await fetchListProvince(filter)
            if (data && data.status === 200) {
                setListCommune(
                    data.data.map((res) => {
                        return {
                            label: res.name,
                            value: res.id,
                        }
                    })
                )
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    useEffect(() => {
        getListProvince()
    }, [])

    useEffect(() => {
        if (province) {
            setIsDisableDistrict(false)
        }
        if (district) {
            setIsDisableCommune(false)
        }
    }, [province, district])

    useEffect(() => {
        let address = getSessionAddressReview()

        if (address) {
            setName(address.username)
            setNote(address.note)
            setPhone(address.phoneNumber)
            setAddress(address.address)
            setProvince({
                value: address.provinceId,
                label: address.provinceName,
            })
            setDistrict({
                value: address.districtId,
                label: address.districtName,
            })
            setCommune({
                value: address.wardId,
                label: address.wardName,
            })
        }
    }, [])

    const getListProvince = async () => {
        try {
            dispatch(toggleShowLoading(true))
            const data = await fetchListProvince()
            dispatch(toggleShowLoading(false))
            if (data && data.status === 200) {
                setListProvince(
                    data.data.map((res) => {
                        return {
                            label: res.name,
                            value: res.id,
                        }
                    })
                )
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    // useEffect(() => {
    //     getListAddress()
    // }, [])
    useEffect(() => {
        getListProduct()
    }, [isRefresh])

    const getListProduct = async () => {
        try {
            const data = await fetchProductFromCart()
            if (data && data.status === 200 && data.data) {
                let total_price = 0;
                let total_commission = 0;
                let dataCoupon = localStorage.getItem('cartCoupon');

                if(dataCoupon) {
                    dataCoupon = JSON.parse(dataCoupon)?.filter(item =>item != null);
                }

                    let dataProdCouponCart = data.data.shoppingCartItems.map(item => {
                        if(dataCoupon?.length > 0) {
                            item['dataCoupon'] = dataCoupon?.filter(element => element?.itemCode == item.itemCode)[0]?.coupon
                        }
                        return item;
                    });
                
                    dataProdCouponCart.forEach((res) => {
                    if (res && res.product) {
                        let cpDiscount = renderDiscount(res);
                        let prodDiscount = cpDiscount ? renderPriceAfterCP(res.productPrice*res.quantity, cpDiscount) : res.productPrice*res.quantity
                        total_price += parseInt(Number(
                            prodDiscount).toFixed(0))
                        total_commission += parseInt(Number(convertTotalCommission(prodDiscount, res.quantity, res.product.commissionValue, res.product.commissionType)))
                    }
                })
                setListProductFromCart(dataProdCouponCart);
                setTotalPrice(total_price);
                setTotalCommission(total_commission);
                setCouponLocal(dataCoupon);
            }
        } catch (e) {
            throw e
        }
    }

    const onChangeQuantity = async (index, variantId, type) => {
        try {
            let newListProduct = [...listProductFromCart]
            let quantity = newListProduct[index].quantity
            if (quantity < 10 && type === 'plus') {
                quantity += 1
            }
            if (quantity > 1 && type === 'minus') {
                quantity -= 1
            }
            const data = {
                cartItemList: [
                    {
                        itemCode: newListProduct[index].itemCode,
                        quantity: quantity,
                        variantId: variantId,
                    },
                ],
            }
            dispatch(toggleShowLoading(true))
            await updateProductCart(data)
            setIsRefresh(moment())
            dispatch(toggleShowLoading(false))
            newListProduct[index].quantity = quantity
            setListProductFromCart(newListProduct)
        } catch (e) {
            if (e && e.response && e.response.data) {
                if (e.response.data.code === 'E00018') {
                    message.error('Bạn đã chọn hết số lượng còn lại của sản phẩm')
                }
                if (e.response.data.code === 'E00043') {
                    message.error(e.response.data.message)
                }
            }
            dispatch(toggleShowLoading(false))
            throw e
        }
    }

    const handleConfirmDelete = (index, itemCode) => {
        setDataConfirmDelete({ index, itemCode })
        dispatch(toggleShowConfirmDelete(true))
    }

    const handleDeleteProduct = async (index, itemCode) => {
        try {
            const data = {
                cartItemList: [
                    {
                        itemCode: itemCode,
                    },
                ],
            }
            if(couponLocal?.length > 0) {
                let itemCoupon = couponLocal?.filter(item => item?.itemCode != itemCode);
                localStorage.setItem('cartCoupon', JSON.stringify(itemCoupon));
            }
            const result = await deleteProductCart(data)
            if (result && result.status === 200) {
                dispatch(toggleShowConfirmDelete(false))
                setIsRefresh(moment())
                dispatch(setRefreshCart(moment()))
                let newListProduct = [...listProductFromCart]
                newListProduct.splice(index, 1)
                setListProductFromCart(newListProduct)
            } else {
                dispatch(toggleShowConfirmDelete(false))
                message.error(result.message)
            }
        } catch (e) {
            dispatch(toggleShowConfirmDelete(false))
            throw e
        }
    }

    const handleCreateOrder = async () => {
        try {
            if (listProductFromCart && listProductFromCart.length > 0) {
                //let filter = /([0-9]{9})/g
                let phoneFilter = /((09|03|07|08|05)+([0-9]{8})\b)/g
                let isValidForm = true
                if (!name) {
                    setValidateName({ status: 'error', text: 'Vui lòng nhập tên người mua' })
                    isValidForm = false
                }
                if (!phone) {
                    setValidatePhone({ status: 'error', text: 'Vui lòng nhập số điện thoại' })
                    isValidForm = false
                }
                if (!phoneFilter.test(phone)) {
                    setValidatePhone({ status: 'error', text: 'Vui lòng nhập đúng số điện thoại' })
                    isValidForm = false
                }
                if (!address) {
                    setValidateAddress({ status: 'error', text: 'Vui lòng nhập địa chỉ' })
                    isValidForm = false
                }
                if (!province) {
                    setValidateProvince({ status: 'error', text: 'Vui lòng chọn tỉnh / thành phố' })
                    isValidForm = false
                }
                if (!district) {
                    setValidateDistrict({ status: 'error', text: 'Vui lòng nhập quận / huyện' })
                    isValidForm = false
                }
                if (!commune) {
                    setValidateCommune({ status: 'error', text: 'Vui lòng nhập phường / xã' })
                    isValidForm = false
                }

                if(!isValidForm) {
                    return
                }

                let orderData = {
                    shippingAddress: {
                        username: name,
                        phoneNumber: phone,
                        note: note,
                        country: 'Việt Nam',
                        address,
                        provinceId: province.value,
                        districtId: district.value,
                        wardId: commune.value,
                        provinceName: province.label,
                        districtName: district.label,
                        wardName: commune.label,
                    },
                    cartItemList: [],
                }
                orderData.cartItemList = listProductFromCart.map((res) => {
                    return {
                        itemCode: res.itemCode,
                    }
                })
                saveAddressReview({
                    username: name,
                    phoneNumber: phone,
                    note: note,
                    country: 'Việt Nam',
                    address,
                    provinceId: province.value,
                    districtId: district.value,
                    wardId: commune.value,
                    provinceName: province.label,
                    districtName: district.label,
                    wardName: commune.label,
                })
                history.push('/checkout/review')
            } else {
                message.error('Vui lòng chọn sản phẩm')
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))
            history.push('/that-bai')
            throw e
        }
    }

    const onClose = () => {
        history.goBack()
    }
    return (
        <CreateManyOrder
            listAddress={listAddress}
            name={name}
            phone={phone}
            note={note}
            address={address}
            district={district}
            province={province}
            commune={commune}
            // onChangeCoupon={onChangeCoupon}
            setListProvince={setListProvince}
            isDisableDistrict={isDisableDistrict}
            isDisableCommune={isDisableCommune}
            listProvince={listProvince}
            listDistrict={listDistrict}
            listCommune={listCommune}
            validateName={validateName}
            validatePhone={validatePhone}
            validateAddress={validateAddress}
            validateProvince={validateProvince}
            validateDistrict={validateDistrict}
            validateCommune={validateCommune}
            onChangeIsDefault={onChangeIsDefault}
            onChangeIsCompanyAddress={onChangeIsCompanyAddress}
            handleChangeName={handleChangeName}
            handleChangePhone={handleChangePhone}
            handleChangeNote={handleChangeNote}
            handleChangeAddress={handleChangeAddress}
            handleChangeProvince={handleChangeProvince}
            handleChangeDistrict={handleChangeDistrict}
            handleChangeCommune={handleChangeCommune}
            // idAddress={idAddress}
            isCompanyAddress={isCompanyAddress}
            isDefault={isDefault}
            totalPrice={totalPrice}
            totalCommission={totalCommission}
            listProductFromCart={listProductFromCart}
            setIsRefresh={setIsRefresh}
            isAddress={isAddress}
            dataConfirmDelete={dataConfirmDelete}
            onClose={onClose}
            onChangeQuantity={onChangeQuantity}
            handleCreateOrder={handleCreateOrder}
            handleDeleteProduct={handleDeleteProduct}
            handleConfirmDelete={handleConfirmDelete}
            setCouponLocal={setCouponLocal}
            couponLocal={couponLocal}
            setListProductFromCart={setListProductFromCart}
            setTotalPrice={setTotalPrice}
            setTotalCommission={setTotalCommission}
        />
    )
}

export default CreateManyOrderContainer
