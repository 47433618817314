import React, { useEffect, useState } from 'react'
import CheckOutStep1 from '../../component/buyer/check-out/CheckOutStep1'
import { useRouteMatch, withRouter } from 'react-router'
import { connect, useDispatch } from 'react-redux'

import { message } from 'antd'
import { handleSetIsAddress } from '../../redux/actions/checkout-action'
import { fetchAddressUser, fetchListProvince } from '../../api-services/address-api'

import { createWholeSale } from '../../api-services/order-id'

import { getSessionAddressReview, getSessionListAddress, saveAddressReview } from '../../functions/common-func'
import * as queryString from 'query-string'

import WholeSale from '../../component/product-detail/WholeSale'
import { toggleShowLoading } from '../../redux/actions/home-actions'
import { getDetailProduct } from '../../api-services/product-api'

import { fetchProductFromCart } from '../../api-services/cart-api'

function WholeSaleContainer({ history, isAddress, isLoggedIn }) {

    const [listProductFromCart, setListProductFromCart] = useState([])

    const [idProduct, setIdProduct] = useState(null)
    // kích thước, màu sắc 
    const [color, setColor] = useState('')
    const [size, setSize] = useState('')
    // values input
    const [validateName, setValidateName] = useState({ status: '', text: '' })
    const [validatePhone, setValidatePhone] = useState({ status: '', text: '' })
    const [validateQuantity, setValidateQuantity] = useState({ status: '', text: '' })

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [quantity, setQuantity] = useState('')

    const dispatch = useDispatch()
    const match = useRouteMatch()

    if(!isLoggedIn) {
        window.location = `${window.location.origin}/login`;
    }
    localStorage.removeItem('cartCoupon');
    

    const clearValidate = () => {
        setValidateName({ status: '', text: '' })
        setValidatePhone({ status: '', text: '' })
        setValidateQuantity({ status: '', text: '' })

    }

    const handleChangeName = (e) => {
        setName(e.target.value)
        clearValidate()
    }

    const handleChangePhone = (e) => {
        setPhone(e.target.value)
        clearValidate()
    }

    const handleChangeQuantity = (e) => {

        const re = /^[0-9\b]+$/; //rules
        if (e.target.value === "" || re.test(e.target.value)) {
            setQuantity(e.target.value)
            clearValidate()
        }

    }

    const onBack = () => {
        history.goBack()
    }



    useEffect(() => {
        const id = match.params.id
        if (id) {
            getDataDetail(id)
            setIdProduct(id)
            const _listAddress = getSessionListAddress()
            if (_listAddress.length > 0) {
                _listAddress.forEach(async (res) => {
                    if (res.isDefault === true) {
                        if (isAddress) {
                            dispatch(handleSetIsAddress(isAddress))
                        } else {
                            dispatch(handleSetIsAddress(res))
                        }
                    }
                })
            }
        }
        // else {
        //     getListAddress()
        //     getListProductFromCart()
        // }
    }, [])

    const getDataDetail = async (id) => {
        try {
            dispatch(toggleShowLoading(true))
            const data = await getDetailProduct(id)
            dispatch(toggleShowLoading(false))
            if (data && data.status === 200) {
                setListProductFromCart([
                    {
                        product: data.data,
                        productId: data.data.id,
                        quantity: 1,
                    },
                ])
                let total_price = 0

                total_price += parseInt(Number(data.data.price).toFixed(0))

            } else {
                message.error(data.message)
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))

            throw e
        }
    }
    const onClose = () => {
        if (history && history.length > 1) {
            history.goBack()
        } else {
            window.location.href = '/'
        }
    }




    const handleWholeSale = async () => {
        try {
            if (listProductFromCart && listProductFromCart.length > 0) {
                if (idProduct) {
                    //let filter = /([0-9]{9})/g
                    let phoneFilter = /((09|03|07|08|05)+([0-9]{8})\b)/g
                    let isValidForm = true
                    if (!name) {
                        setValidateName({ status: 'error', text: 'Vui lòng nhập tên người mua' })
                        isValidForm = false
                    }
                    if (!phone) {
                        setValidatePhone({ status: 'error', text: 'Vui lòng nhập số điện thoại' })
                        isValidForm = false
                    }
                    if (!phoneFilter.test(phone)) {
                        setValidatePhone({ status: 'error', text: 'Vui lòng nhập đúng số điện thoại' })
                        isValidForm = false
                    }
                    if (!quantity) {
                        setValidateQuantity({ status: 'error', text: 'Vui lòng nhập số lượng' })
                        isValidForm = false
                    }

                    if (!isValidForm) {
                        return
                    }


                    let orderData = {

                        name: name,
                        phone: phone,
                        quantity: Number(quantity),
                        productName: listProductFromCart[0].product.name
                    }

                    dispatch(toggleShowLoading(true))
                    const result = await createWholeSale(orderData)
                    dispatch(toggleShowLoading(false))

                    if (result && result.status === 200) {

                        message.success('Gửi thành công')
                        onClose()

                    } else {
                        message.success('Gửi có lỗi')
                        onClose()
                    }

                }
            } else {
                message.error('Có lỗi xảy ra vui lòng thử lại')
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))
            if (e && e.response && e.response.data && e.response.data.code === 'E00024') {
                message.error(e.response.data.message.message)
                return
            }
            onClose()
            throw e
        }
    }
    return (
        <WholeSale

            listProductFromCart={listProductFromCart}
            onBack={onBack}
            handleWholeSale={handleWholeSale}
            name={name}
            phone={phone}
            quantity={quantity}
            validateName={validateName}
            validatePhone={validatePhone}
            validateQuantity={validateQuantity}
            handleChangeName={handleChangeName}
            handleChangePhone={handleChangePhone}
            handleChangeQuantity={handleChangeQuantity}
            onClose={onClose}
        />
    )
}
const mapStateToProps = function (state) {
    return {
        toggleCart: state.homeReducer.toggleCart,
        isLoggedIn: state.homeReducer.isLoggedIn
        //isAddress: state.checkoutReducer.isAddress,
    }
}
export default withRouter(connect(mapStateToProps)(WholeSaleContainer))

