import React, { useEffect, useState } from 'react'
import BoxHeaderTitle from '../../common/BoxHeaderTitle'
import { message, Radio, Button } from 'antd'
import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { handleSetIdAddress, handleSetIsAddress, toggleShowActionAddress } from '../../../redux/actions/checkout-action'
import BoxEditDeleteAddress from '../../common/BoxEditDeleteAddress'
import { toggleShowLoading } from '../../../redux/actions/home-actions'
import { fetchAddressUser } from '../../../api-services/address-api'
import * as queryString from 'query-string'
import { Link } from 'react-router-dom'
import { getSessionListAddress } from '../../../functions/common-func'

function BoxAddress({ dispatch, location, history, isPublisher, isLoggedIn }) {
    const [listAddress, setListAddress] = useState([])
    const [addressId, setAddressId] = useState(null)
    const [refresh, setRefresh] = useState(false)
    const [isDefault, setIsDefault] = useState(false)


    if(!isLoggedIn) {
        window.location = `${window.location.origin}/login`;
    }

    useEffect(() => {
        const query = queryString.parse(location.search)
        const _addressId = query.addressId
        setAddressId(_addressId)
        if (isLoggedIn) {
            getListAddress()
        } else {
            let list = getSessionListAddress()
            setListAddress(list)
            list.forEach((res) => {
                if (res.isDefault === true) {
                    setIsDefault(res)
                }
            })
        }
    }, [refresh])

    const getListAddress = async () => {
        try {
            dispatch(toggleShowLoading(true))
            const data = await fetchAddressUser()
            dispatch(toggleShowLoading(false))
            if (data && data.status === 200) {
                setListAddress(data.data)
                data.data.forEach((res) => {
                    if (res.isDefault === true) {
                        setIsDefault(res)
                    }
                })
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }
    const onChange = (value) => {
        setAddressId(value.id)
        dispatch(handleSetIsAddress(value))
    }

    const onBack = () => {
        history.goBack()
    }

    return (
        <div className={`box__address`}>
            <BoxHeaderTitle text="Địa chỉ nhận hàng " onClose={onBack} />
            <div className="box__address__content">
                <div className="list__address">
                    <Radio.Group value={addressId}>
                        {Array.isArray(listAddress) &&
                            listAddress.length > 0 &&
                            listAddress.map((res) => {
                                return (
                                    <div className="box__address__item">
                                        <Radio onChange={() => onChange(res)} className="form-radio" value={res.id}>
                                            <div className="address__item">
                                                <div className="address__item__text">
                                                    <p className="info">
                                                        {' '}
                                                        {res.username} - {res.phoneNumber}{' '}
                                                    </p>
                                                    <p className="address">{res.address}</p>
                                                </div>
                                            </div>
                                        </Radio>
                                        <div
                                            className="address__item__icon"
                                            onClick={() => {
                                                dispatch(toggleShowActionAddress(true))
                                                dispatch(handleSetIdAddress(res.id))
                                            }}
                                        >
                                            <MoreOutlined />
                                        </div>
                                    </div>
                                )
                            })}
                    </Radio.Group>
                </div>
                <div className="add__address box__address__item">
                    <span onClick={() => history.push('/address/create')}>
                        {' '}
                        <PlusOutlined /> Thêm địa chỉ mới
                    </span>
                </div>
                <div className="container">
                    <div className="btn__footer">
                        <Button type="primary" size="large" className="w-100">
                            <Link to={isPublisher === 1 ? '/checkout/cart' : '/thanh-toan'}>Giao đến địa chỉ này</Link>
                        </Button>
                    </div>
                </div>
                <BoxEditDeleteAddress isDefault={isDefault} refresh={refresh} setRefresh={setRefresh} />
            </div>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        isChangeAddress: state.checkoutReducer.isChangeAddress,
        isActionAddress: state.checkoutReducer.isActionAddress,
        isPublisher: state.homeReducer.isPublisher,
        isLoggedIn: state.homeReducer.isLoggedIn,
    }
}
export default withRouter(connect(mapStateToProps)(BoxAddress))
