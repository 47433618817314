import React, { useEffect, useState } from 'react'
import BoxHeaderTitle from '../common/BoxHeaderTitle'
import { CheckCircleOutlined } from '@ant-design/icons'
import DisplayPrice from '../common/DisplayPrice'
import { Button, message, Modal } from 'antd'
import { useHistory, useRouteMatch, withRouter } from 'react-router'
import { connect, useDispatch } from 'react-redux'
import { IconHomeChietKhau } from '../../constants/icon-const'
import {
    checkMinMax,
    convertCommission, convertTotalCommission,
    getIdPublisher,
    getSessionAddressReview,
    isMaxTableScreen,
    isMobileScreen,
    removeSession,
    renderDiscount,
    renderPriceAfterCP,
    saveAddressReview,
} from '../../functions/common-func'
import ErrorProduct from '../common/ErrorProduct'
// import { Link } from 'react-router-dom'
import { deleteProductCart, fetchProductFromCart, updateProductCart } from '../../api-services/cart-api'
import { toggleShowLoading } from '../../redux/actions/home-actions'
import { createOrder, createOrderBuyOne, createOrderCoupon } from '../../api-services/order-id'
import { getDetailProduct } from '../../api-services/product-api'
import * as queryString from 'query-string'
import BreadcrumbNavigation from '../common/BreadcrumbNavigation'
import * as errMsg from '../../constants/err-msg'
import ShowNote from '../create-order/ShowNote'
import CouponsSelector from '../coupon/CouponsSelector'
import { setRefreshCart } from '../../redux/actions/search-action'
import moment from 'moment'
import { toggleShowNotBuy } from '../../redux/actions/create-order-action'
function CheckoutReview({ location, isPublisher, isLoggedIn,isShowNotBuy }) {
    const [listProductFromCart, setListProductFromCart] = useState([])
    const [totalPrice, setTotalPrice] = useState(0);
    const [isRefresh, setIsRefresh] = useState(false);
    const [totalCommission, setTotalCommission] = useState(0)
    const [idProduct, setIdProduct] = useState(0)
    const [color, setColor] = useState('')
    const [size, setSize] = useState('')
    const [pubId, setPubId] = useState(null);
    const [couponLocal, setCouponLocal] = useState([]);
    const history = useHistory()
    const dispatch = useDispatch()
    let address = getSessionAddressReview()
    let pub_id = getIdPublisher()
    const match = useRouteMatch()

    if(!isLoggedIn || isPublisher !== 1) {
        window.location = `${window.location.origin}/login`;
    }

    useEffect(() => {
        if (pub_id) {
            setPubId(pub_id)
        }
    }, [location])

    const options = [
        {
            label: 'Tạo đơn',
        },
        {
            label: '>',
        },
        {
            label: 'Kiểm tra',
            active: true,
        },
        {
            label: '>',
        },
        {
            label: 'Hoàn thành',
        },
    ]

    useEffect(() => {
        const id = match.params.id;
        const query = queryString.parse(location.search)
        let quantity = Number(query.quantity);
        if (!quantity || Number.isNaN(quantity)) {
            quantity = 1;
        }
        if(quantity > 10) {
            quantity = 10;
        }

        if (id) {
            setIdProduct(id)
            getDataDetail(id, quantity)
        } else {
            getListProduct()
        }
    }, [isRefresh])
    

    useEffect(() => {
        const query = queryString.parse(location.search)
        if (query.color) {
            setColor(query.color)
            setSize(query.size)
        }
    }, [location])

    // useEffect(()=> {
    //     if(!address){
    //         history.pus
    //     }
    // }, [])

    const getDataDetail = async (id, quantity) => {
        try {
            dispatch(toggleShowLoading(true))
            const data = await getDetailProduct(id)
            dispatch(toggleShowLoading(false))
            if (data && data.status === 200) {
                setListProductFromCart([
                    {
                        product: data.data,
                        productId: data.data.id,
                        quantity: quantity,
                    },
                ])
                let total_price = 0
                
                total_price += parseInt(Number(data.data.price * quantity).toFixed(0))
                setTotalPrice(total_price)
            } else {
                message.error(data.message)
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))

            throw e
        }
    }

    const getListProduct = async () => {
        try {
            const data = await fetchProductFromCart()
            if (data && data.status === 200 && data.data) {
                setListProductFromCart(data.data.shoppingCartItems);
                let total_price = 0;
                let total_commission = 0;
                let dataCoupon = localStorage.getItem('cartCoupon');

                if(dataCoupon) {
                    dataCoupon = JSON.parse(dataCoupon)?.filter(item =>item != null || item != undefined);
                }

                let dataProdCouponCart = data.data.shoppingCartItems.map(item => {
                        if(dataCoupon?.length > 0) {
                            item['dataCoupon'] = dataCoupon?.filter(element => element?.itemCode == item.itemCode)[0]?.coupon
                        }
                        return item;
                    });
                data.data.shoppingCartItems.forEach((res) => {
                    if (res && res.product) {
                        let cpDiscount = renderDiscount(res);
                        let prodDiscount = cpDiscount ? renderPriceAfterCP(res.productPrice*res.quantity, cpDiscount) : res.productPrice*res.quantity
                        total_price += parseInt(Number(prodDiscount).toFixed(0))
                        total_commission += parseInt(Number(convertTotalCommission(prodDiscount, res.quantity, res.product.commissionValue, res.product.commissionType)))
                    }
                })
                setListProductFromCart(dataProdCouponCart);
                setTotalPrice(total_price);
                setTotalCommission(total_commission);
                setCouponLocal(dataCoupon);
            }
        } catch (e) {
            throw e
        }
    }

    const removeCart = async (itemCode) => {
        let data = {
            cartItemList: [
                {
                    itemCode: itemCode,
                },
            ],
        }
        return await deleteProductCart(data);
    }

    const handleCreateOrder = async () => {
        try {
            if (listProductFromCart && listProductFromCart.length > 0) {
                if (!address) {
                    history.push('/checkout/cart')
                    message.error('Vui lòng nhập địa chỉ')
                    return
                }
                if (idProduct) {
                    let note = ''
                    if (color) {
                        note += 'Color:' + color
                    }
                    if (size) {
                        if (color) note += ','
                        note += 'Size:' + size
                    }
                    if (color) note += ','
                    note += 'Quantity:' + listProductFromCart[0].quantity
                    let _orerData = {
                        shippingAddress: address,
                        product: {
                            productId: idProduct,
                            note,
                            quantity: listProductFromCart[0].quantity,
                            productAttributes: [],
                        },
                    }
                    if (pubId) {
                        _orerData.pubId = pubId
                    }
                    dispatch(toggleShowLoading(true))
                    const result = await createOrderBuyOne(_orerData)
                    dispatch(toggleShowLoading(false))
                    if (result) {
                        if (result && result.status === 400) {
                            history.push('/checkout/cart')
                            message.error(result.message ? result.message : 'Có lỗi xẩy ra! Xin vui lòng thư lại sau')
                            return
                        }
                        if (result && result.status === 200) {
                            if (result.data && ['success', 'pending'].includes(result.data.status)) {
                                history.push('/thanh-cong')
                            }
                            if (result.data && result.data.status === 'fail') {
                                history.push('/that-bai')
                            }
                        } else {
                            history.push('/that-bai')
                        }
                    }
                } else {
                    let result;
                    let existCoupon = listProductFromCart.every(item => {
                        if(item.dataCoupon)  return item;
                    });
                    console.log(existCoupon);
                    let orderData = {
                        shippingAddress: address,
                        cartItemList: [],
                    };
                    if(existCoupon) {
                        let getNote = (product, coupon) => {
                            if(product?.note && product?.note != '') {
                              return `CouponCode: ${coupon?.code}, ${product?.note}`;
                            };
                            return `CouponCode: ${coupon?.code}`;
                        };
                        let cartItemList = listProductFromCart.map((res) => {
                            return {
                                itemCode: res.itemCode,
                                variantId: res.variantId,
                                productId: res.productId,
                                quantity:res.quantity,
                                note: res.dataCoupon ? getNote(res, res.dataCoupon) : res.note, 
                                couponId: res.dataCoupon ? res.dataCoupon.id : 0,
                                couponCode: res.dataCoupon? res.dataCoupon.code :''
                            };
                        });
                        dispatch(toggleShowLoading(true))
                        const rs = await updateProductCart({cartItemList:cartItemList});
                        if(rs.status === 200 && rs.data?.shoppingCartItems) {
                            localStorage.removeItem('cartCoupon');
                            orderData.cartItemList = rs.data.shoppingCartItems.map((res) => {
                                    return {
                                        itemCode: res.itemCode,
                                        variantId: res.variantId,
                                        couponId: res.couponId,
                                        couponCode: res.coupon ? res.coupon.code:''
                                    }
                                });
                                result = await createOrderCoupon(orderData);
                                if (result && result.status === 400) {
                                    history.push('/checkout/cart');
                                    message.error(result.message ? result.message : 'Có lỗi xẩy ra! Xin vui lòng thư lại sau');
                                    return;
                                }
                                if (result && result.status === 200) {
                                    if (result.data && ['success', 'pending'].includes(result.data.status)) {
                                        listProductFromCart.forEach(item => {
                                            removeCart(item.itemCode);
                                        });
                                        dispatch(toggleShowLoading(false))
                                        history.push(`/thanh-cong?order_id=${result.data?.orderItems?.[0].id}`);
                                        removeSession('address_review');
                                        dispatch(setRefreshCart(moment()));
                                    }
                                    if (result.data && result.data.status === 'fail') {
                                        dispatch(toggleShowLoading(false))
                                        history.push('/that-bai');
                                    };
                                } else {
                                    dispatch(toggleShowLoading(false))
                                    history.push('/that-bai');
                                };
                        } else {
                            history.push('/checkout/cart');
                            message.error(rs.message ? rs.message : 'Có lỗi xẩy ra! Xin vui lòng thư lại sau');
                            return;
                        };
                    } else {
                        orderData.cartItemList = listProductFromCart.map((res) => {
                            return {
                                itemCode: res.itemCode,
                                variantId: res.variantId,
                                couponId: res.dataCoupon ? res.dataCoupon.id : 0,
                                couponCode: res.dataCoupon? res.dataCoupon.code :''
                            }
                        });
                        console.log(orderData)
                        dispatch(toggleShowLoading(true));
                        console.log(result)
                        result = await createOrder(orderData);
                        console.log(result)
                        if (result && result.status === 400) {
                            history.push('/checkout/cart')
                            message.error(result.message ? result.message : 'Có lỗi xẩy ra! Xin vui lòng thư lại sau')
                            return;
                        }
                        if (result && result.status === 200) {
                            if (result.data && ['success', 'pending'].includes(result.data.status)) {
                                listProductFromCart.forEach(item => {
                                    removeCart(item.itemCode);
                                });
                                localStorage.removeItem('cartCoupon');
                                dispatch(toggleShowLoading(false));
                                history.push(`/thanh-cong?order_id=${result.data?.orderItems?.[0].id}`)
                                removeSession('address_review');
                                dispatch(setRefreshCart(moment()));
                            }
                            if (result.data && result.data.status === 'fail') {
                                dispatch(toggleShowLoading(false));
                                history.push('/that-bai')
                            }
                        } else {
                            dispatch(toggleShowLoading(false));
                            history.push('/that-bai');
                        }   
                    }
                }
            } else {
                message.error('Vui lòng chọn sản phẩm')
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))
            if (e && e.response && e.response.data && e.response.data.code === 'error') {
                message.error(e.response.data.message)
                return
            }
            if (e && e.response && e.response.data && e.response.data.code === 'E00024') {
                message.error(e.response.data.message)
                return
            }
            if (e && e.response && e.response.data && e.response.data.code === 'E00038') {
                message.error(errMsg.ERR_MSG_E00038)
                return
            }
            if (e && e.response && e.response.data && e.response.data.code === 'E00025') {
                message.error(e.response.data.message)
                removeSession('pub_id')
                history.push('/that-bai')
                return
            }
             history.push('/that-bai')
            throw e
        }
    }

    return (
        <div className="check__out__review create__order">
            <BoxHeaderTitle
                text="Xác nhận thông tin đơn hàng   "
                link={`${
                    isPublisher === 1 ? '/checkout/cart' : `/thanh-toan/${idProduct}?color=${color}&size=${size}`
                }`}
                isBell={true}
            />
            <div className="create__order__content">
                <div className="container">
                    <BreadcrumbNavigation option={options} />
                    <div className="create__order__content__desktop">
                        <div className="create__order__right">
                            {isMobileScreen() && isMaxTableScreen() && (
                                <div className="item__order border__bottom">
                                    <div className="container__mobile">
                                        <div className="step__order">
                                            <div className="item__step active">
                                                <div className="icon">
                                                    <span className="success">
                                                        <CheckCircleOutlined style={{ fontSize: 18 }} />
                                                    </span>
                                                    <span className="step">1</span>
                                                </div>
                                                <span className="text">Tạo đơn</span>
                                            </div>
                                            <div className="item__step">
                                                <div className="icon">
                                                    <span className="success">
                                                        <CheckCircleOutlined />
                                                    </span>
                                                    <span className="step">2</span>
                                                </div>
                                                <span className="text">Xem đơn</span>
                                            </div>
                                            <div className="item__step waiting">
                                                <div className="icon">
                                                    <span className="success">
                                                        <CheckCircleOutlined />
                                                    </span>
                                                    <span className="step">3</span>
                                                </div>
                                                <span className="text">Đặt đơn</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="item__order product border__bottom">
                                <div className="container__mobile">
                                    <h2 className="title">Danh sách sản phẩm đang chọn</h2>
                                    <div className="list__product">
                                        {Array.isArray(listProductFromCart) &&
                                            listProductFromCart.length > 0 &&
                                            listProductFromCart.map((res, index) => {
                                                let cpDiscount = renderDiscount(res);
                                                
                                                let priceAfterChoose = cpDiscount ? renderPriceAfterCP(res.productPrice*res.quantity, cpDiscount) : res.productPrice*res.quantity;
                                                return (
                                                    <div
                                                        key={index}
                                                        className="product__content product__one__content"
                                                    >
                                                        <div className="product__image">
                                                            <img src={res.product.thumbImage} alt="" />
                                                        </div>
                                                        <div className="product__detail">
                                                            <p className="name">{res.product.name}</p>
                                                            {(res.note && res.note != "") && <ShowNote note={ res.note } /> }
                                                            <p className="price__product">
                                                                <span>Giá thành:</span>
                                                                <span className="price">
                                                                    {!res?.variantData && <DisplayPrice
                                                                        price={res.productPrice}
                                                                    />}
                                                                    {res?.variantData && 
                                                                        <DisplayPrice price={res.productPrice} /> }
                                                                    
                                                                </span>
                                                            </p>
                                                            <p className="price__product">
                                                                <span>Số lượng:</span>
                                                                <span className="price">{res.quantity}</span>
                                                            </p>
                                                            {isPublisher === 1 && (
                                                                <p className="price__product commission">
                                                                    <span>Hoa hồng:</span>
                                                                    <span className="price">
                                                                        <IconHomeChietKhau />
                                                                        <DisplayPrice
                                                                            price={convertCommission(
                                                                                res.productPrice,            
                                                                                res.product.commissionValue,
                                                                                res.product.commissionType
                                                                            )}
                                                                        />
                                                                    </span>
                                                                </p>
                                                            )}
                                                            <div className="product__voucher">
                                                                <div className='title'>
                                                                    <span>Coupon sản phẩm:</span>
                                                                </div>
                                                                <CouponsSelector
                                                                    setIsRefresh = {setIsRefresh}
                                                                    product={res}
                                                                    listProduct={listProductFromCart}
                                                                    cpDiscount={cpDiscount}
                                                                    setTotalPrice={setTotalPrice}
                                                                    setTotalCommission={setTotalCommission}
                                                                    setCouponLocal={setCouponLocal}
                                                                    couponLocal={couponLocal}
                                                                 />
                                                            </div>
                                                            <p className="price__product" style={{paddingTop: "10px", borderTop: "dashed 1px #eee", marginTop: "10px"}}>
                                                                <span>Tổng giá:</span>
                                                                <span className="price">
                                                                 <span className="price">
                                                                    <DisplayPrice
                                                                        price={res && res.product && (priceAfterChoose)}
                                                                    />
                                                                </span>
                                                                
                                                                </span>
                                                            </p>
                                                            {isPublisher === 1 &&  (
                                                                <p className="price__product commission">
                                                                    <span>Tổng hoa hồng:</span>
                                                                    <span className="price">
                                                                        <IconHomeChietKhau />
                                                                        <DisplayPrice
                                                                            price={convertTotalCommission(
                                                                                priceAfterChoose,
                                                                                res.quantity,
                                                                                res.product.commissionValue,
                                                                                res.product.commissionType
                                                                            )}
                                                                        />
                                                                    </span>
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                    {listProductFromCart && listProductFromCart.length === 0 && <ErrorProduct />}
                                </div>
                            </div>
                        </div>
                        <div className="create__order__left">
                            {address && (
                                <div className="item__order">
                                    <div className="container__mobile">
                                        <div className="booker__profile">
                                            <h2 className="title">Thông tin người mua</h2>
                                            <div className="profile__item">
                                                <span>Họ và tên:</span>
                                                <p>{address.username}</p>
                                            </div>
                                            <div className="profile__item">
                                                <span>Số điện thoại:</span>
                                                <p>{address.phoneNumber}</p>
                                            </div>
                                            <div className="profile__item">
                                                <span>Địa chỉ:</span>
                                                <p>
                                                    {address.address}, {address.wardName}, {address.districtName},{' '}
                                                    {address.provinceName}
                                                </p>
                                            </div>
                                            {address.note && (
                                                <div className="profile__item">
                                                    <span>Ghi chú:</span>
                                                    <p>{address.note}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="btn__footer">
                                <div className="container__mobile">
                                    <div className="total__price1">
                                        <span style={{ fontSize: '18px'}}>Thành tiền: &nbsp;</span>
                                        <span className="price" style={{ fontSize: '18px', fontWeight: '600', color: '#F5222D'}}>
                                            <DisplayPrice price={totalPrice} />
                                        </span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        {isPublisher === 1 && (
                                            <span>
                                                <span className="price" style={{fontSize: '14px', lineHeight: '16px', display: 'flex', fontWeight: '600', color: '#1890FF'}}>
                                                    <IconHomeChietKhau />
                                                    <DisplayPrice price={totalCommission} />
                                                </span>
                                            </span>
                                        )}
                                    </div>
                                    <Button
                                        // onClick={handleCreateOrder} 
                                        onClick={() => message.warning('Vui lòng sử dụng tính năng này trên ứng dụng DiMuaDi')}
                                        style={{ margin: '10px 0', borderRadius: 4 }}
                                        size="large"
                                        className="w-100"
                                        type="primary"
                                    >
                                        Hoàn tất vào tạo đơn
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                centered
                visible={isShowNotBuy}
                footer={null}
                onOk={() => dispatch(toggleShowNotBuy(false))}
                onCancel={() => dispatch(toggleShowNotBuy(false))}
            >
                <p style={{fontSize:"20px", padding:"15px"}} className="text-center">Vui lòng sử dụng tính năng này trên ứng dụng DiMuaDi</p>
            </Modal>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        isPublisher: state.homeReducer.isPublisher,
        isLoggedIn: state.homeReducer.isLoggedIn,
        isShowNotBuy: state.createOrderReducer.isShowNotBuy
    }
}
export default withRouter(connect(mapStateToProps)(CheckoutReview))
