import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import * as queryString from 'query-string'
import moment from 'moment'
import { message } from 'antd'
import { connect } from 'react-redux'
import ProductDetail from '../../component/product-detail/ProductDetail'
import { fetchListCampaign, fetchListProduct, getDetailProduct } from '../../api-services/product-api'
import { togglePopupShare, toggleShowLoading } from '../../redux/actions/home-actions'
import { addProductToCart, fetchProductFromCart } from '../../api-services/cart-api'
import { setRefreshCart, toggleChooseContent, toggleShowBoxConfirmCart } from '../../redux/actions/search-action'
import { checkHaveChild, convertOldVariations, convertSizeImage, convertVariations, copyToClipboard, getSession } from '../../functions/common-func'
import { fetchListTop } from '../../api-services/home-api'
import { DEFAULT_IMAGE } from '../../constants/common-contants'
import * as _ from 'lodash'
import { getCouponsByProductID} from '../../api-services/coupon-product-api'
function ProductDetailContainer({ location, dispatch, history, isLoggedIn, isPublisher }) {

    const [dataDetail, setDataDetail] = useState(false)
    const [contentShare, setContentShare] = useState(null)
    const [deepLink, setDeepLink] = useState('')
    const [dataCampaign, setDataCampaign] = useState(false)
    const [relateProduct, setRelateProduct] = useState({})
    const [paging, setPaging] = useState({ page: 1, pageSize: 15, total: 1 })
    const [parentIndex, setParentIndex] = useState(-1);
    const [childIndex, setChildIndex] = useState(-1);
    const [variantId, setVariantId] = useState(null);
    const [parentKey, setParentKey] = useState(null);
    const [childKey, setChildKey] = useState(null);
    const [parentValue, setParentValue] = useState(null);
    const [childValue, setChildValue] = useState(null);
    const [dataCoupons, setDataCoupons] = useState([]);
    const [dataCouponModal, setDataCouponModal] = useState([]);
    const [pagingCoupon, setPagingCoupon] = useState({ page: 1, pageSize: 25, total: 1 }) 
    const [variPrice, setVariPrice] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const [listCart, setListCart] = useState([]);
    const [oldVariation, setOldVariation] = useState([]);

    if(!isLoggedIn) {
        window.location = `${window.location.origin}/login`;
    }

    useEffect(() => {
        const pathname = location.pathname
        const id = pathname.split('/').slice(-1).pop()
        getDataDetail(id);
        getListProductCoupon(id,showMore,1,3);
        getListCart();
        localStorage.removeItem('cartCoupon');
    }, [location]);

    useEffect(() => {
        const pathname = location.pathname
        const id = pathname.split('/').slice(-1).pop()
        if(showMore) {
            getListProductCoupon(id,showMore,1,pagingCoupon.pageSize);
        }
    },[showMore])


    useEffect(() => {
        if (dataDetail && dataDetail.supplierId && isLoggedIn && isPublisher) {
            getDataCampaign(dataDetail.supplierId)
        }
    }, [dataDetail])

    

    useEffect(() => {
        if (dataDetail) {
            const query = queryString.parse(location.search)
            let page = query.page || 1
            let filter = '?category_id=' + dataDetail.categoryId
            filter += '&page=' + page
            getDataProduct(filter)
        }
    }, [dataDetail, location])

    const getListCart = async () => {
        try {
            const data = await fetchProductFromCart()
            if (data?.status === 200 && data?.data) {
                setListCart(data?.data?.shoppingCartItems);
            }
        } catch (e) {
            throw e
        }
    };

    const getListProductCoupon = async (id,statusMore,page,page_size) => {
                try {
                    const data = await getCouponsByProductID(id,statusMore,page,page_size)
                    if (data && data.status === 200) {
                        if(statusMore) {
                            setDataCouponModal(data.data);
                            setPagingCoupon(data.paging);
                        } else {
                            setDataCoupons(data);
                        }
                    } else {
                        message.error(data.message);
                    }
                } catch (e) {
                    throw e
                }
            }
    
    const getDataProduct = async (filter) => {
        try {
            const _listProduct = await fetchListProduct(filter)
            if (_listProduct && _listProduct.status === 200) {
                if (_listProduct.data && _listProduct.data.length > 0) {
                    setRelateProduct(_listProduct.data)
                    setPaging(_listProduct.paging)
                } else {
                    const params = {}
                    params.page = 1
                    params.page_size = 12
                    const _listTop = await fetchListTop(params)
                    if (_listTop && _listTop.status === 200) {
                        setRelateProduct(_listTop.data)
                        setPaging(_listTop.paging)
                    }
                }
            }
        } catch (e) {
            throw e
        }
    }

    const handleShareProduct = async (boolean, type, title) => {
        if (dataDetail && dataDetail.contents && dataDetail.contents.length > 0) {
            dispatch(toggleChooseContent(true))
        } else {
            if (navigator.share === undefined) {
                dispatch(togglePopupShare(boolean, type, title))
            } else {
                try {
                    let shareData = {}
                    if (type === 'link') {
                        copyToClipboard(deepLink || '')
                        if (deepLink) {
                            message.success('Sao chép đường dẫn thành công')
                        }
                        shareData = {
                            title: dataDetail.name,
                            text: dataDetail.name || '',
                            url:
                                dataDetail && dataDetail.videos && dataDetail.videos.length > 0
                                    ? dataDetail.videos[0]
                                    : dataDetail.thumbImage,
                        }
                    }
                    await navigator.share(shareData)
                    message.success('Chia sẻ thành công')
                } catch (error) {
                    throw error
                }
            }
        }
    }

    const getDataCampaign = async (id) => {
        try {
            const data = await fetchListCampaign(id)
            if (data && data.data && data.status === 200) {
                setDataCampaign(data.data)
            }
        } catch (e) {
            throw e
        }
    }

    const getDataDetail = async (id) => {
        let user = getSession('userInfo')?.userInfo

        try {
            dispatch(toggleShowLoading(true))
            const data = await getDetailProduct(id)
            dispatch(toggleShowLoading(false))
            if (data && data.status === 200) {
                setDataDetail(data.data)
                if (data.data && data.data.contents && data.data.contents.length > 0) {
                    setContentShare(data.data.contents[0]) // set nôi dung chia sẻ ban đầu là phần tử đầu tiên của mảng
                }
                if (isLoggedIn && user) {
                    let linkShare = process.env.REACT_APP_URL_SHARE_V2 + '/product/' + id + '?pub_id=' + user.id // link chia sẻ cho pub
                    setDeepLink(linkShare)
                }
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    const handleContentShare = (content) => {
        setContentShare(content)
    }

    const onClose = () => {
        if (history && history.length > 1) {
            history.goBack()
        } else {
            window.location.href = '/'
        }
    }
    
    const handleCreateOrder = async (id, quantity,coupon) => {
        // variation mới
        const variations = convertVariations(dataDetail);
        //variation cũ
        const old_variations = convertOldVariations(dataDetail);
        try {
            if (dataDetail.status === 3) {
                message.error('Sản phẩm đang hết hàng, vui lòng quay lại sau');
                return;
            }
            // if(listCart.some(item => item.couponId == coupon.id)) {
            //     message.error('Voucher đã được áp dựng trong giỏ hàng');
            //     return;
            // }
            // trường hợp có variations theo cms mới 
            if (variations?.length > 0 && parentIndex === -1 && childIndex === -1) {
                message.error('Vui lòng chọn loại sản phẩm');
                return;
            }
            // Có 1 thuộc tính
            if (variations?.length > 0 && !checkHaveChild(variations) && parentIndex < 0) {
                message.error(`Vui lòng chọn ${variations[0].attributeName}`);
                return;
            }
            // Có 2 thuộc tính thiếu parent
            if (variations?.length > 0 && checkHaveChild(variations) && parentIndex < 0) {
                message.error(`Vui lòng chọn ${variations[0].attributeName}`);
                return;
            }
            // Có 2 thuộc tính thiếu child
            if (variations?.length > 0 && checkHaveChild(variations) && childIndex < 0) {
                message.error(`Vui lòng chọn ${variations[0].children[0].attributeName}`);
                return;
            }

            // Trường hợp variation cũ
            if(old_variations?.length > 0 && variations?.length <= 0 ) {
                if(oldVariation.length <=0) {
                    message.error('Vui lòng chọn loại sản phẩm');
                    return;
                } else if(oldVariation.length < old_variations?.length) {
                    const not_choose_variation = old_variations.filter(item => item.id != oldVariation[0].id)
                    message.error(`Vui lòng chọn ${not_choose_variation[0].attribute_name}`);
                    return;
                }
            }

            if (!isLoggedIn) {
                // trường hợp là buyer
                const data= {
                    couponId:coupon? coupon.id : 0,
                    productId: id,
                    quantity: quantity,
                    parentKey: parentKey,
                    productPrice: variPrice,
                }
                if(variations.length > 0) {
                    if (parentKey) {
                        data.parentKey = parentKey
                        data.parentValue = parentValue
                    }
                    if (childKey) {
                        data.childKey = childKey
                        data.childValue = childValue
                    }
                }
                localStorage.setItem('cartList', JSON.stringify(data))
                history.push(`/thanh-toan/${id}`)
            } else {
                // trường hợp là publisher
                const getNote = () => {
                    let note = '';
                    if(variations.length > 0) {
                        if (parentKey && !childKey) {
                            note += `${parentKey}: ${parentValue}`
                        }
                        if (parentKey && childKey) {
                            note += `${parentKey}: ${parentValue} , ${childKey}: ${childValue}` 
                        }
                    } else if(old_variations?.length == 1 ) {
                        oldVariation.forEach(item => {
                            note+=`${item.slug}: ${item.attribute_value}`
                        })
                    } else if(old_variations?.length > 1) {
                        oldVariation.forEach(item => {
                            note+=`${item.slug}: ${item.attribute_value}, `
                        })
                        note = note.slice(0, -2);
                    }
                    return note;
                }
                let data = {
                    productId: id,
                    quantity: 1,
                    productAttributes: [],
                    variantId: variantId,
                    note: getNote(),
                };
                const result = await addProductToCart(data);
                if (result && result.status === 200) {
                    let index = result.data.shoppingCartItems?.findIndex(item => item.note == data.note); 
                    let dataCoupon = [{
                        itemCode: result.data.shoppingCartItems[index]?.itemCode,
                        coupon: coupon,
                        note:getNote()
                    }]
                    localStorage.setItem('cartCoupon', JSON.stringify(dataCoupon))
                    history.push('/checkout/cart');
                    dispatch(setRefreshCart(moment()));
                }
            }

        } catch (e) {
            if (e && e.response && e.response.data) {
                if (e.response.data.code === 'E00018' && quantity > 0) {
                    message.error(
                        'Bạn đã chọn hết số lượng còn lại của sản phẩm, không thể chọn thêm được nữa, vui lòng kiểm tra giỏ hàng'
                    )
                    return
                }
                if (e.response.data.code === 'E00018') {
                    message.error('Sản phầm đã hết hàng')
                    return
                } else {
                    message.error(e.response.data.message)
                }
            }
            throw e
        }
    }

    const wholeSale = async (id, quantity,name) => {

        history.push(`/whole-sale/${id}`)
    }


    return (
        <ProductDetail
            dataDetail={dataDetail}
            deepLink={deepLink}
            dataCampaign={dataCampaign}
            contentShare={contentShare}
            relateProduct={relateProduct}
            paging={paging}
            onClose={onClose}
            dataCoupons={dataCoupons}
            setDataCoupons={setDataCoupons}
            handleShareProduct={handleShareProduct}
            handleCreateOrder={handleCreateOrder}
            handleContentShare={handleContentShare}
            wholeSale={wholeSale}
            dataCouponModal={dataCouponModal}
            setDataCouponModal={setDataCouponModal}
            pagingCoupon={pagingCoupon}
            setPagingCoupon={setPagingCoupon}
            history={history}
            parentIndex={parentIndex}
            childIndex={childIndex}
            setParentIndex={setParentIndex}
            setChildIndex={setChildIndex}
            showMore={showMore}
            setShowMore={setShowMore}
            variantId = {variantId}
            setVariantId={setVariantId}
            setParentKey={setParentKey}
            setChildKey={setChildKey}
            setParentValue={setParentValue}
            setChildValue={setChildValue}
            setVariPrice={setVariPrice}
            variPrice={variPrice}
            oldVariation={oldVariation}
            setOldVariation={setOldVariation}
        />
    )
}

const mapStateToProps = function (state) {
    return {
        isLoggedIn: state.homeReducer.isLoggedIn,
        isPublisher: state.homeReducer.isPublisher,
    }
}
export default withRouter(connect(mapStateToProps)(ProductDetailContainer))
