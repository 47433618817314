import React, { useEffect, useState } from 'react'
import BoxHeaderTitle from '../../common/BoxHeaderTitle'
import { fetchListCategory } from '../../../api-services/home-api'
import HotDirectory from '../../list-product/HotDirectory'
import { Link } from 'react-router-dom'
import { convertSizeImage } from '../../../functions/common-func'
import { useSelector } from 'react-redux'

function ListCategory() {
    const [listCategory, setListCategory] = useState([])

    const { isLoggedIn } = useSelector((state) => {
        return {
            isLoggedIn: state.homeReducer.isLoggedIn,
        }
    })

    if(!isLoggedIn) {
        window.location = `${window.location.origin}/login`;
    }
    localStorage.removeItem('cartCoupon');

    useEffect(() => {
        getListCategory()
    }, [])

    const getListCategory = async () => {
        try {
            const data = await fetchListCategory()
            if (data && data.status === 200) {
                setListCategory(data.data)
            }
        } catch (e) {
            throw e
        }
    }
    return (
        <div className="search-product">
            <BoxHeaderTitle text="Danh mục sản phẩm" link={'/'} isBell={true} />
            <div className="search__product__content">
                <div className={`home__list__options home__product__item`}>
                    <div className="container">
                        <div className="title">
                            Các danh mục sản phẩm hot nhất tại Dimuadi
                        </div>
                        <div className="list__options">
                            {Array.isArray(listCategory) &&
                                listCategory.length > 0 &&
                                listCategory.map((category) => {
                                    return (
                                        <div key={category.id} className="option__item">
                                            <Link to={`/list-product?category_id=${category.id}`}>
                                                <div className="option__icon">
                                                    <img src={convertSizeImage(category.image)} alt={category.code} />
                                                </div>
                                                <p>{category.name}</p>
                                            </Link>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListCategory
