import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Checkbox, message } from 'antd'
import Select from 'react-select'
import moment from 'moment'
import 'moment/min/locales.min'
import BoxHeaderTitle from '../common/BoxHeaderTitle'
import { useRouteMatch, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { createAddress, fetchAddressById, fetchListProvince, updateAddressById } from '../../api-services/address-api'
import { toggleShowLoading } from '../../redux/actions/home-actions'
import { getAddressByIdSession, getSessionListAddress, saveListAddress } from '../../functions/common-func'
import TextArea from 'antd/es/input/TextArea'

function FormBooker({ isBooker, dispatch, history, location, isPublisher, isLoggedIn }) {
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [note, setNote] = useState('')
    const [address, setAddress] = useState('')
    const [province, setProvince] = useState(false)
    const [district, setDistrict] = useState(false)
    const [commune, setCommune] = useState(false)
    const [isDisableDistrict, setIsDisableDistrict] = useState(true)
    const [isDisableCommune, setIsDisableCommune] = useState(true)
    const [listProvince, setListProvince] = useState([])
    const [listDistrict, setListDistrict] = useState([])
    const [listCommune, setListCommune] = useState([])
    const [isDefault, setIsDefault] = useState(false)
    const [idAddress, setIdAddress] = useState(false)
    const [isCompanyAddress, setIsCompanyAddress] = useState(false)
    const [validateName, setValidateName] = useState({ status: '', text: '' })
    const [validatePhone, setValidatePhone] = useState({ status: '', text: '' })
    const [validateAddress, setValidateAddress] = useState({ status: '', text: '' })
    const [validateProvince, setValidateProvince] = useState({ status: '', text: '' })
    const [validateDistrict, setValidateDistrict] = useState({ status: '', text: '' })
    const [validateCommune, setValidateCommune] = useState({ status: '', text: '' })
    const match = useRouteMatch()

    // const onChangeIsDefault = (e) => {
    //     setIsDefault(e.target.checked)
    // }
    //
    // const onChangeIsCompanyAddress = (e) => {
    //     setIsCompanyAddress(e.target.checked)
    // }

    if(!isLoggedIn) {
        console.log(isLoggedIn)
        console.log(window.location.host)
        window.location = `${window.location.origin}/login`;
    }

    useEffect(() => {
        const _idAddress = match.params.id
        if (_idAddress) {
            setIdAddress(_idAddress)
            if (isLoggedIn) {
                getDataAddressById(_idAddress)
            } else {
                let address = getAddressByIdSession(_idAddress)
                if (address) {
                    setName(address.username)
                    setNote(address.note)
                    setPhone(address.phoneNumber)
                    setProvince({
                        label: address.provinceName,
                        value: address.provinceId,
                    })
                    getListDistrict(address.provinceId)
                    getListCommune(address.districtId)
                    setDistrict({
                        label: address.districtName,
                        value: address.districtId,
                    })
                    setCommune({
                        label: address.wardName,
                        value: address.wardId,
                    })
                    setAddress(address.address)
                    address.isDefault && setIsDefault(address.isDefault)
                    address.isCompanyAddress && setIsCompanyAddress(address.isCompanyAddress)
                }
            }
        }
    }, [location])

    useEffect(() => {
        getListProvince()
    }, [])

    useEffect(() => {
        if (province) {
            setIsDisableDistrict(false)
        }
        if (district) {
            setIsDisableCommune(false)
        }
    }, [province, district])

    const getListProvince = async () => {
        try {
            dispatch(toggleShowLoading(true))
            const data = await fetchListProvince()
            dispatch(toggleShowLoading(false))
            if (data && data.status === 200) {
                setListProvince(
                    data.data.map((res) => {
                        return {
                            label: res.name,
                            value: res.id,
                        }
                    })
                )
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    const getDataAddressById = async (idAddress) => {
        try {
            dispatch(toggleShowLoading(true))
            const data = await fetchAddressById(idAddress)
            dispatch(toggleShowLoading(false))
            if (data && data.status === 200) {
                setName(data.data.username)
                setNote(data.data.note)
                setPhone(data.data.phoneNumber)
                setProvince({
                    label: data.data.provinceName,
                    value: data.data.provinceId,
                })
                await getListDistrict(data.data.provinceId)
                await getListCommune(data.data.districtId)
                setProvince({
                    label: data.data.districtName,
                    value: data.data.districtId,
                })
                setProvince({
                    label: data.data.wardName,
                    value: data.data.wardId,
                })
                setAddress(data.data.address)
                data.data.isDefault && setIsDefault(data.data.isDefault)
                data.data.isCompanyAddress && setIsCompanyAddress(data.data.isCompanyAddress)
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    const getListDistrict = async (province) => {
        try {
            let filter = '?type=district'
            if (province) {
                filter += `&parent_id=${province}`
            }
            const data = await fetchListProvince(filter)
            if (data && data.status === 200) {
                setListDistrict(
                    data.data.map((res) => {
                        return {
                            label: res.name,
                            value: res.id,
                        }
                    })
                )
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    const getListCommune = async (district_id) => {
        try {
            let filter = '?type=ward'
            if (district_id) {
                filter += `&parent_id=${district_id}`
            }
            const data = await fetchListProvince(filter)
            if (data && data.status === 200) {
                setListCommune(
                    data.data.map((res) => {
                        return {
                            label: res.name,
                            value: res.id,
                        }
                    })
                )
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    const clearValidate = () => {
        setValidateName({ status: '', text: '' })
        setValidatePhone({ status: '', text: '' })
        setValidateAddress({ status: '', text: '' })
        setValidateProvince({ status: '', text: '' })
        setValidateDistrict({ status: '', text: '' })
        setValidateCommune({ status: '', text: '' })
    }

    const handleChangeName = (e) => {
        setName(e.target.value)
        clearValidate()
    }

    const handleChangePhone = (e) => {
        setPhone(e.target.value)
        clearValidate()
    }
    const handleChangeNote = (e) => {
        setNote(e.target.value)
    }

    const handleChangeAddress = (e) => {
        setAddress(e.target.value)
        clearValidate()
    }

    const handleChangeProvince = async (value) => {
        setProvince(value)
        setDistrict(null)
        setCommune(null)
        clearValidate()
        if (value) {
            await getListDistrict(value.value)
        }
    }

    const handleChangeDistrict = async (value) => {
        setDistrict(value)
        setCommune(null)
        if (value) {
            await getListCommune(value.value)
        }
        clearValidate()
    }

    const handleChangeCommune = (value) => {
        setCommune(value)
        clearValidate()
    }

    const handleAddAddress = async () => {
        //let filter = /([0-9]{9})/g
        let phoneFilter = /((09|03|07|08|05)+([0-9]{8})\b)/g
        let isValidForm = true
        if (!name) {
            setValidateName({ status: 'error', text: 'Vui lòng nhập tên người mua' })
            isValidForm = false
        }
        if (!phone) {
            setValidatePhone({ status: 'error', text: 'Vui lòng nhập số điện thoại' })
            isValidForm = false
        }
        if (!phoneFilter.test(phone)) {
            setValidatePhone({ status: 'error', text: 'Vui lòng nhập đúng số điện thoại' })
            isValidForm = false
        }
        if (!address) {
            setValidateAddress({ status: 'error', text: 'Vui lòng nhập địa chỉ' })
            isValidForm = false
        }
        if (!province) {
            setValidateProvince({ status: 'error', text: 'Vui lòng chọn tỉnh / thành phố' })
            isValidForm = false
        }
        if (!district) {
            setValidateDistrict({ status: 'error', text: 'Vui lòng nhập quận / huyện' })
            isValidForm = false
        }
        if (!commune) {
            setValidateCommune({ status: 'error', text: 'Vui lòng nhập phường / xã' })
            isValidForm = false
        }

        if(!isValidForm) {
            return
        }

        try {
            let data = {
                username: name,
                phoneNumber: phone,
                address: address,
                provinceId: province.value,
                country: 'Việt Nam',
                districtId: district.value,
                wardId: commune.value,
                provinceName: province.label,
                districtName: district.label,
                wardName: commune.label,
                isDefault: isDefault,
                isCompanyAddress,
                note: note,
            }
            if (idAddress) {
                if (isLoggedIn) {
                    dispatch(toggleShowLoading(true))
                    const result = await updateAddressById(idAddress, data)
                    if (result && result.status === 200) {
                        message.success('Thay đổi địa chỉ thành công')
                        // history.push(`${isPublisher === 1 ? '/checkout/cart' : '/thanh-toan'}`)
                        history.goBack()

                    } else {
                        message.error(result.message)
                    }
                    dispatch(toggleShowLoading(false))
                } else {
                    let listAddress = getSessionListAddress()
                    listAddress.forEach((res, index) => {
                        if (res.id === idAddress) {
                            listAddress.splice(index, 1)
                        }
                    })
                    data.id = idAddress
                    listAddress.push(data)
                    saveListAddress(listAddress)
                    message.success('Thay đổi địa chỉ thành công')
                    // history.push(`${isPublisher === 1 ? '/checkout/cart' : '/thanh-toan'}`)
                    history.goBack()
                }
                return
            }
            if (!isLoggedIn) {
                let listAddress = getSessionListAddress()
                data.id = moment().format('YYYYMMDDHHMMSS')
                if (listAddress.length === 0) {
                    data.isDefault = true
                    listAddress.push(data)
                } else {
                    if (data.isDefault) {
                        listAddress.forEach((res) => {
                            res.isDefault = false
                        })
                        listAddress.push(data)
                    }
                    listAddress.push(data)
                }
                saveListAddress(listAddress)
                message.success('Thêm địa chỉ thành công')
                history.goBack()
            } else {
                dispatch(toggleShowLoading(true))
                const result = await createAddress(data)
                dispatch(toggleShowLoading(false))

                if (result && result.status === 200) {
                    message.success('Thêm địa chỉ thành công')
                    history.goBack()
                } else {
                    message.error(result.message)
                }
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))
            if (e && e.response && e.response.data) {
                message.error(e.response.data.message)
            }
            throw e
        }
    }

    const onBack = () => {
        history.goBack()
    }

    return (
        <div className={`booker__form ${isBooker ? 'open' : ''}`}>
            <BoxHeaderTitle text="Thông tin người mua" onClose={onBack} />
            <div className="booker__form__content">
                <Form>
                    <div className="form-group">
                        <label>Người mua</label>
                        <Form.Item
                            className="el-input__inner"
                            validateStatus={validateName.status}
                            help={validateName.text}
                        >
                            <Input
                                value={name}
                                onChange={handleChangeName}
                                placeholder="Nhập tên người mua"
                                size="large"
                            />
                        </Form.Item>
                    </div>
                    <div className="form-group">
                        <label>Số điện thoại</label>
                        <Form.Item
                            className="el-input__inner"
                            validateStatus={validatePhone.status}
                            help={validatePhone.text}
                        >
                            <Input
                                value={phone}
                                onChange={handleChangePhone}
                                placeholder="Nhập số điện thoại"
                                size="large"
                            />
                        </Form.Item>
                    </div>
                    <div className="form-group">
                        <label>Địa chỉ chi tiết</label>
                        <Form.Item
                            validateStatus={validateAddress.status}
                            help={validateAddress.text}
                            className="el-input__inner"
                        >
                            <Input
                                value={address}
                                onChange={handleChangeAddress}
                                placeholder="Nhập số nhà và đường"
                                size="large"
                            />
                        </Form.Item>
                    </div>
                    <div className="form-group">
                        <label>Tỉnh/Thành phố</label>
                        <Form.Item validateStatus={validateProvince.status} help={validateProvince.text}>
                            <Select
                                value={province}
                                onChange={handleChangeProvince}
                                options={listProvince}
                                isClearable
                                placeholder="Tỉnh/Thành phố"
                                className="department_sender"
                            />
                            {/*<Select*/}
                            {/*    placeholder={'Tỉnh/Thành phố'}*/}
                            {/*    style={{ width: '100%' }}*/}
                            {/*    onChange={handleChangeProvince}*/}
                            {/*    size="large"*/}
                            {/*    value={province}*/}
                            {/*    showSearch*/}
                            {/*>*/}
                            {/*    {Array.isArray(listProvince) &&*/}
                            {/*        listProvince.length > 0 &&*/}
                            {/*        listProvince.map((res) => {*/}
                            {/*            return (*/}
                            {/*                <Option key={res.id} value={res.id}>*/}
                            {/*                    {res.name}*/}
                            {/*                </Option>*/}
                            {/*            )*/}
                            {/*        })}*/}
                            {/*</Select>*/}
                        </Form.Item>
                    </div>
                    <div className="form-group">
                        <label>Quận/huyện</label>
                        <Form.Item validateStatus={validateDistrict.status} help={validateDistrict.text}>
                            <Select
                                value={district}
                                onChange={handleChangeDistrict}
                                options={listDistrict}
                                isClearable
                                isDisabled={isDisableDistrict}
                                placeholder="Quận/huyện"
                                className="department_sender"
                            />
                            {/*<Select*/}
                            {/*    onChange={handleChangeDistrict}*/}
                            {/*    placeholder={'Quận/huyện'}*/}
                            {/*    style={{ width: '100%' }}*/}
                            {/*    size="large"*/}
                            {/*    value={district}*/}
                            {/*    disabled={isDisableDistrict}*/}
                            {/*    showSearch*/}
                            {/*>*/}
                            {/*    {Array.isArray(listDistrict) &&*/}
                            {/*        listDistrict.length > 0 &&*/}
                            {/*        listDistrict.map((res) => {*/}
                            {/*            return (*/}
                            {/*                <Option key={res.id} value={res.id}>*/}
                            {/*                    {res.name}*/}
                            {/*                </Option>*/}
                            {/*            )*/}
                            {/*        })}*/}
                            {/*</Select>*/}
                        </Form.Item>
                    </div>
                    <div className="form-group">
                        <label>Phường/Xã</label>
                        <Form.Item validateStatus={validateCommune.status} help={validateCommune.text}>
                            <Select
                                value={commune}
                                onChange={handleChangeCommune}
                                options={listCommune}
                                isClearable
                                isDisabled={isDisableCommune}
                                placeholder="Phường/Xã"
                                className="department_sender"
                            />
                            {/*<Select*/}
                            {/*    onChange={handleChangeCommune}*/}
                            {/*    disabled={isDisableCommune}*/}
                            {/*    placeholder={'Phường/Xã'}*/}
                            {/*    style={{ width: '100%' }}*/}
                            {/*    size="large"*/}
                            {/*    value={commune}*/}
                            {/*    showSearch*/}
                            {/*>*/}
                            {/*    {Array.isArray(listCommune) &&*/}
                            {/*        listCommune.length > 0 &&*/}
                            {/*        listCommune.map((res) => {*/}
                            {/*            return (*/}
                            {/*                <Option key={res.id} value={res.id}>*/}
                            {/*                    {res.name}*/}
                            {/*                </Option>*/}
                            {/*            )*/}
                            {/*        })}*/}
                            {/*</Select>*/}
                        </Form.Item>
                    </div>
                    <div className="form-group">
                        <label>Ghi chú</label>
                        <Form.Item className="el-input__inner">
                            <TextArea
                                value={note}
                                onChange={handleChangeNote}
                                placeholder="Ghi chú ..."
                            />
                        </Form.Item>
                    </div>
                    {/*<div className="form-group">*/}
                    {/*    <Checkbox checked={isDefault} onChange={onChangeIsDefault}>*/}
                    {/*        {' '}*/}
                    {/*        Địa chỉ mặc định{' '}*/}
                    {/*    </Checkbox>*/}
                    {/*</div>*/}
                    {/*<div className="form-group">*/}
                    {/*    <Checkbox checked={isCompanyAddress} onChange={onChangeIsCompanyAddress}>*/}
                    {/*        {' '}*/}
                    {/*        Địa chỉ công ty{' '}*/}
                    {/*    </Checkbox>*/}
                    {/*</div>*/}
                </Form>
                <div className="form__btn">
                    <Button onClick={handleAddAddress} size="large" type="primary" className="w-100">
                        Giao đến địa chỉ này
                    </Button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        isBooker: state.createOrderReducer.isBooker,
        isPublisher: state.homeReducer.isPublisher,
        isLoggedIn: state.homeReducer.isLoggedIn,
    }
}
export default withRouter(connect(mapStateToProps)(FormBooker))
