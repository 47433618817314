import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import ListProduct from '../../component/list-product/ListProduct'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { fetchListHotWeek, fetchListProduct } from '../../api-services/product-api'
import { fetchListBanner, fetchListCategory } from '../../api-services/home-api'
import * as queryString from 'query-string'
import { toggleSortProduct } from '../../redux/actions/search-action'
import { SORT_ITEM } from '../../constants/search-const'

function ListProductContainer({ history, location, dispatch, isLoggedIn, isPublisher }) {
    const [listProduct, setListProduct] = useState(null)
    const [listCategory, setListCategory] = useState([])
    const [listHotWeek, setListHotWeek] = useState([])
    const [listBanner, setListBanner] = useState(false)
    const [nameProduct, setName] = useState('')
    const [isSort, setIsSort] = useState({ value: 'new', label: 'Hàng mới', id: 1 })
    const [isSearch, setIsSearch] = useState(false)
    const [paging, setPaging] = useState({ page: 1, pageSize: 24, total: 1 })
    const [reLoadingSuggestion, setReLoadingSuggestion] = useState(false)
    // const [categoryId, setCategoryId] = useState('')
    const [validateName, setValidateName] = useState({ status: 'success', text: '' })

    if(!isLoggedIn) {
        window.location = `${window.location.origin}/login`;
    }
    localStorage.removeItem('cartCoupon');

    useEffect(() => {
        const query = queryString.parse(location.search)
        const _name = query.name || ''
        const category_id = query.category_id || ''
        const category_name = query.category_name || ''
        const min_price = query.min_price || ''
        const max_price = query.max_price || ''
        const sort_types = query.sort_types || ''
        const sort_mode = query.sort_mode || ''
        const page = query.page || paging.page
        const pageSize = query.pageSize || paging.pageSize
        const brand = query.brand || ''
        let filter = '?'
        if (category_id) filter += `category_id=${category_id}`
        if (category_name) filter += `&category_name=${category_name}`
        if (_name) filter += `&name=${_name}`
        if (min_price) filter += `&min_price=${min_price}`
        if (max_price) filter += `&max_price=${max_price}`
        if (sort_types) filter += `&sort_types=${sort_types}`
        if (brand) filter += `&brand=${brand}`
        if (sort_mode) filter += `&sort_mode=${sort_mode}`
        if (page) filter += `&page=${page}`
        if (pageSize) filter += `&page_size=${pageSize}`
        if (sort_types === 'price' && sort_mode) {
            let type = sort_types + '_' + sort_mode
            setIsSort(SORT_ITEM.filter((res) => res.value === type)[0])
        }
        if (_name || min_price || max_price || brand || sort_types) {
            setIsSearch(true)
        } else {
            setIsSearch(false)
        }
        setName(_name)
        // setCategoryId(category_id)
        getListProduct(filter)
    }, [location])

    useEffect(() => {
        let check = !!(paging.page < paging.totalPage)
        if (paging && reLoadingSuggestion && check) {
            const query = queryString.parse(location.search)
            const _name = query.name || ''
            const category_id = query.category_id || ''
            const category_name = query.category_name || ''
            const min_price = query.min_price || ''
            const max_price = query.max_price || ''
            const sort_types = query.sort_types || ''
            const sort_mode = query.sort_mode || ''
            const brand = query.brand || ''
            let filter = '?'
            filter += `page=${paging.page + 1}&page_size=${paging.pageSize}`
            if (category_id) filter += `&category_id=${category_id}`
            if (category_name) filter += `&category_name=${category_name}`
            if (_name) filter += `&name=${_name}`
            if (min_price) filter += `&min_price=${min_price}`
            if (max_price) filter += `&max_price=${max_price}`
            if (sort_types) filter += `&sort_types=${sort_types}`
            if (brand) filter += `&brand=${brand}`
            if (sort_mode) filter += `&sort_mode=${sort_mode}`
            if (sort_types === 'price' && sort_mode) {
                let type = sort_types + '_' + sort_mode
                setIsSort(SORT_ITEM.filter((res) => res.value === type)[0])
            }
            getListProduct(filter)
        }
    }, [reLoadingSuggestion])

    useEffect(() => {
        getListCategory()
        getListHotWeek()
        getListBanner()
    }, [])

    const getListProduct = async (filter) => {
        try {
            const data = await fetchListProduct(filter)

            if (data && data.status === 200) {
                setListProduct(data.data)
                if (reLoadingSuggestion) {
                    let _list = [...listProduct]
                    _list = _list.concat(data.data)
                    setListProduct(_list)
                } else {
                    setListProduct(data.data)
                }
                setPaging(data.paging)
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    const getListBanner = async () => {
        try {
            const data = await fetchListBanner()
            if (data && data.status === 200) {
                setListBanner(data.data)
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    const getListHotWeek = async () => {
        try {
            const data = await fetchListHotWeek()
            if (data && data.status === 200) {
                setListHotWeek(data.data)
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    const getListCategory = async () => {
        try {
            const data = await fetchListCategory()
            if (data && data.status === 200) {
                setListCategory(data.data)
            }
        } catch (e) {
            throw e
        }
    }

    const handleKeyPressName = (e) => {
        if (e.charCode === 13) {
            const query = queryString.parse(location.search)
            let new_query = { ...query }
            nameProduct ? (new_query = { ...query, name: nameProduct }) : delete new_query.name
            const stringify = queryString.stringify(new_query)
            history.push(`${location.pathname}?${stringify}`)
        }
    }

    const handleClick = () => {
        const query = queryString.parse(location.search)
        let new_query = { ...query }
        nameProduct ? (new_query = { ...query, name: nameProduct }) : delete new_query.name
        const stringify = queryString.stringify(new_query)
        history.push(`${location.pathname}?${stringify}`)
    }

    const handleChangeName = (e) => {
        setName(e.target.value)
        setValidateName({ status: 'success', text: '' })
    }

    const handleChangeSort = (item) => {
        let mode = ''
        let type = item.value
        if (item && item.value && (item.value === 'price_asc' || item.value === 'price_desc')) {
            mode = item.value.split('_')[1]
            type = item.value.split('_')[0]
        }
        const query = queryString.parse(location.search)
        let new_query = { ...query }
        mode ? (new_query.sort_mode = mode) : delete new_query.sort_mode
        type ? (new_query.sort_types = type) : delete new_query.sort_types
        const stringify = queryString.stringify(new_query)
        setIsSort(item)
        dispatch(toggleSortProduct(false))
        history.push(`${location.pathname}?${stringify}`)
    }

    const onClose = () => {
        history.goBack()
    }

    return (
        <ListProduct
            name={nameProduct}
            isSort={isSort}
            isSearch={isSearch}
            listCategory={listCategory}
            listHotWeek={listHotWeek}
            listProduct={listProduct}
            listBanner={listBanner}
            setReLoadingSuggestion={setReLoadingSuggestion}
            reLoadingSuggestion={reLoadingSuggestion}
            onClose={onClose}
            validateName={validateName}
            handleChangeName={handleChangeName}
            handleKeyPressName={handleKeyPressName}
            handleChangeSort={handleChangeSort}
            handleClick={handleClick}
            paging={paging}
        />
    )
}
const mapStateToProps = function (state) {
    return {
        isLoggedIn: state.homeReducer.isLoggedIn,
        isPublisher: state.homeReducer.isPublisher,
    }
}
export default withRouter(connect(mapStateToProps)(ListProductContainer))
